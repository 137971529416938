/**************************************************
 * Nombre:       Dialogo_Persona
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, useMediaQuery} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {Delete, InventoryOutlined} from "@mui/icons-material";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_redondo.svg'
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {borrarDoc} from "../../Servicios/BD/borrarDoc";
import Formulario_Pedido from "../Formularios/Formulario_Pedido";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {actualizarDoc} from "../../Servicios/BD/actualizarDoc";
import {theme} from "../../Tema";
import Formulario_Copiar from "../Formularios/Formulario_Copiar";
import {obtenerColeccionQuery} from "../../Servicios/BD/obtenerColeccionQuery";
import {collection, query, where} from "firebase/firestore";
import {fire} from "../../fire";
import {obtenerConsecutivo} from "../../Servicios/BD/Consecutivos/obtenerConsecutivo";
import {aumentarConsecutivo} from "../../Servicios/BD/Consecutivos/aumentarConsecutivo";
import {useState} from "react";

const Dialogo_Cotizacion_Ingresar_Items = ({datos, cerrar, realizado}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const {props, obtenerEntidad, setEntidad} = useFormulario({valoresDefecto: datos})
    const {props: propsCopiar, obtenerEntidad: obternerEntidadCopiar} = useFormulario({valoresDefecto: {}})
    const {abrirCargador, cerrarCargador, Cargador} = useLoaders({logo: logo})
    const [copiada,setCopiada] = useState(false)

    const guardarDatos = () => {
        obtenerEntidad().then((entidad) => {
            abrirCargador('Subiendo datos')
            let obj = entidad
            if (!obj.fecha) {
                obj.fecha = new Date().getTime()
            }

            if (!obj.estado) {
                obj.estado = 'Nueva'
            }


            if (obj.consecutivo) {
                guardarCotizacion(obj)
            } else {

                obtenerConsecutivo({col: 'contadores', id: 'cotizaciones'}).then((des) => {
                    if (des.res) {
                        let numero = des.data
                        obj.consecutivo = numero
                        aumentarConsecutivo({col: 'contadores', id: 'cotizaciones', actual: numero}).then((dux) => {
                            if (dux.res) {
                                guardarCotizacion(obj)
                            }
                        })
                    }
                })

                obtenerDoc('contadores', 'cotizaciones').then((dox) => {
                    if (dox.res) {


                    }
                })
            }

        })

    }

    const guardarCotizacion = (obj) => {
        guardarDoc('cotizaciones', obj).then((dox) => {
            cerrarCargador()
            if (dox.res) {
                cerrar()
                realizado()
                alert('Cambios guardados con exito')
            } else {
                alert('Ups algo paso, por favor intentalo nuevamente')
            }
        })
    }

    const traerDatos = () => {
        obternerEntidadCopiar().then((entidad) => {
            abrirCargador('Copiando datos')

            let q = query(collection(fire, 'cotizaciones'), where('consecutivo', '==', parseInt(entidad.numero)))
            obtenerColeccionQuery(q).then((dox) => {
                if (dox.res && dox.data[0]) {
                    let data = dox.data[0]
                    delete data.consecutivo
                    delete data.id
                    delete data.fecha
                    delete data.estado
                    setEntidad(data)
                } else {
                    alert('Numero de cotizacion no encontrada')
                }
                cerrarCargador()
            })
        })
    }

    const borrar = () => {
        abrirCargador('Borrando Cotizacion')
        borrarDoc('cotizaciones', datos.id).then((dox) => {
            cerrar()
            cerrarCargador()
            realizado()
        })
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"

        >

            <Cargador/>

            {!datos &&
                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginBottom: 2}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                            <Formulario_Copiar props={propsCopiar}/>
                        </Grid>

                        <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-end'}}>
                            <Button
                                onClick={() => traerDatos()}
                            >copiar</Button>
                        </Grid>


                    </Grid>
                </Grid>
            }

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Formulario_Pedido props={props} editable={datos && datos.estado !== 'Aprobada'}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 3}}>
                <Button
                    disabled={datos && datos.estado === 'Aprobada'}
                    onClick={() => guardarDatos()}
                    startIcon={<InventoryOutlined/>}
                >{datos ? 'Actualizar Cotizacion' : 'Crear Cotizacion'}</Button>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginBottom: -1, marginTop: 1}}>
                {datos && datos.id &&
                    <Button
                        disabled={datos && datos.estado === 'Aprobada'}
                        onClick={() => borrar()}
                        variant={'text'}
                        sx={{color: '#00000080', px: 0, marginTop: 2}}
                        startIcon={<Delete/>}
                    >Borrar Cotizacion</Button>
                }
            </Grid>

        </Grid>
    )

}
export default Dialogo_Cotizacion_Ingresar_Items