/**************************************************
 * Nombre:       Dialogo_Persona
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Checkbox, Grid, Typography, useMediaQuery} from "@mui/material";
import {Flag, Print, TaskAlt} from "@mui/icons-material";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_redondo.svg'
import {React, useState} from "react";
import {PDFDownloadLink} from "@react-pdf/renderer";
import Pdf_Cotizacion from "../PDfs/Pdf_Cotizacion";
import {theme} from "../../Tema";
import Formulario_Cotizacion_Estado from "../Formularios/Formulario_Cotizacion_Estado";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {obtenerConsecutivo} from "../../Servicios/BD/Consecutivos/obtenerConsecutivo";
import {aumentarConsecutivo} from "../../Servicios/BD/Consecutivos/aumentarConsecutivo";
import {ESTADOSORDEN} from "../../Constantes";
import {borrarDoc} from "../../Servicios/BD/borrarDoc";

const Dialogo_Orden_Aprobar = ({datos, cerrar, realizado}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const {props, obtenerEntidad, limpiarEntidad} = useFormulario({valoresDefecto: datos})
    const {abrirCargador, cerrarCargador, Cargador} = useLoaders({logo: logo})
    const [entendido, setEntendido] = useState(false)


    const aprobarCotizacion = () => {
        obtenerEntidad().then((entidad) => {
            abrirCargador('Subiendo datos')
            let obj = entidad

            let cartera = {...obj}
            delete cartera.id
            cartera.estado = 'Nueva'
            cartera.fechaTerminada = new Date().getTime()

            guardarCartera(cartera, obj)

        })
    }

    const guardarCartera = (orden, obj) => {
        guardarDoc('carteras', orden).then((dox) => {
            if (dox.res) {
                alert('Orden de servicio terminada y cartera creada')
                elimiarCotizacion(obj)
            } else {
                alert('Ups algo paso, por favor intentalo nuevamente')
            }
        })
    }

    const elimiarCotizacion = (obj) => {
        borrarDoc('ordenes', obj.id).then((dox) => {
            cerrar()
            realizado()
            alert('Cambios guardados con exito')
        })
    }
    const guardarDatos = () => {
        obtenerEntidad().then((entidad) => {
            abrirCargador('Subiendo datos')
            let obj = entidad
            guardarCotizacion(obj)

        })

    }


    const guardarCotizacion = (obj) => {
        guardarDoc('ordenes', obj).then((dox) => {
            cerrarCargador()
            if (dox.res) {
                cerrar()
                realizado()
                alert('Cambios guardados con exito')
            } else {
                alert('Ups algo paso, por favor intentalo nuevamente')
            }
        })
    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{pr: 1}}
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <PDFDownloadLink document={<Pdf_Cotizacion datos={datos} orden={true}/>} fileName={`Orden de servicio #${datos.consecutivo}`}
                                 style={{textDecoration: "none"}}>
                    <Button startIcon={<Print/>} variant={'outlined'} sx={{color: theme.palette.primary.main}}>Ver
                        Orden de Servicio</Button>
                </PDFDownloadLink>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 2}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item container lg={2} sm={12} xs={2} sx={{justifyContent: 'flex-start', marginTop: -0.8}}>
                        <Checkbox checked={entendido || datos.estado === 'Aprobada'}
                                  onChange={(e) => setEntendido(!entendido)} size={'small'}/>
                    </Grid>

                    <Grid item container lg={10} sm={12} xs={10} sx={{justifyContent: 'flex-start'}}>
                        <Typography sx={{fontSize: 14}}>
                            {
                                'Al completar esta orden de servicio entiendo que el trabajo ha sido entregado en su totalidad y unicamente hace falta realizar los cobros'
                            }

                        </Typography>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 2, marginBottom: 4}}>
                <Button
                    disabled={!entendido || datos.estado === 'Aprobada'}
                    onClick={() => aprobarCotizacion()}
                    startIcon={<TaskAlt/>}
                >
                    Completar Orden de servicio

                </Button>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', borderTop: 1, paddingTop: 4}}>
                <Formulario_Cotizacion_Estado props={props} editable={datos && datos.estado !== 'Aprobada'}
                                              opciones={ESTADOSORDEN}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 2, marginBottom: 1}}>
                <Button
                    disabled={datos && datos.estado === 'Aprobada'}
                    onClick={() => guardarDatos()}
                    startIcon={<Flag/>}
                >Guardar Cambios</Button>
            </Grid>


        </Grid>
    )

}
export default Dialogo_Orden_Aprobar