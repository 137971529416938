export const comprimirNumero = (num) => {

    if (num < 999999) {
        let res = num / 1000
        return res + 'K'
    }

    if (num < 999999999) {
        let res = num / 1000
        return res + 'M'
    }

    if (num < 999999999999) {
        let res = num / 1000
        return res + 'B'
    }

}