/**************************************************
 * Nombre:       Formulario_Persona
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography, useMediaQuery} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {AccountCircle, Badge, Call, Email, LocationCity, PhoneAndroid} from "@mui/icons-material";
import {React, useEffect, useState} from "react";
import {useIngresarObjeto} from "../../Modulo_formularios/Hooks/useIngresarObjeto";
import {theme} from "../../Tema";
import {formatoMoneda} from "../../Utilidades/formatoMoneda";
import {valorItemsTotal} from "../Utilidades/valorItemsTotal";
import {obtenerIva} from "../Utilidades/obtenerIva";
import {valorItemsTotalCosto} from "../Utilidades/valorItemsTotalCosto";
import Formulario_Incremento from "./Formulario_Incremento";
import {valorTotalDescuentos} from "../Utilidades/valorTotalDescuentos";
import Tarjeta_Incrementos from "../Tarjetas/Tarjeta_Incrementos";

const Formulario_Cotizacion_Incrementos = ({...props}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const {IngresarObjeto} = useIngresarObjeto({
        props: props,
        nombre: 'Ingresar Incremento',
        Formulario: Formulario_Incremento,
        dato: 'incrementos',
        Tarjeta: Tarjeta_Incrementos
    })
    const [totalItems, setTotalItems] = useState(0)
    const [totalCostoItems, setTotalCostoItems] = useState(0)
    const [totalDescuentos, setTotalDescuentos] = useState(0)
    const [totalIncrementos, setTotalIncrementos] = useState(0)
    const [total, setTotal] = useState(0)

    useEffect(() => {

        let arr = props.props.getValues('incrementos')



        if (arr && arr.length > 0) {
            let totInc = 0

            for (let i = 0; i < arr.length; i++) {
                totInc = totInc + arr[i].valorIncremento
            }


            let subTotal = totalItems + totInc - totalDescuentos
            let totalCot = subTotal + (obtenerIva(subTotal))
            setTotal(totalCot)
            setTotalIncrementos(totInc)
            props.props.setValue('valorIncrementos', totInc)
            props.props.setValue('costo', totalCostoItems + totInc + obtenerIva(subTotal))
            props.props.setValue('total', totalCot)

        } else {
            props.props.setValue('valorIncrementos', 0)
            let subTotal = totalItems - totalDescuentos
            let totalCot = subTotal + (obtenerIva(subTotal))
            setTotal(totalCot)
            setTotalIncrementos(0)
            props.props.setValue('costo', totalCostoItems + obtenerIva(subTotal))
            props.props.setValue('total', totalCot)

        }


    }, [props.props.watch(['incrementos'])])


    useEffect(() => {

        let items = props.props.getValues('items')
        let descuentosIt = props.props.getValues('descuentos')
        let totItems = valorItemsTotal(items)
        let totCosto = valorItemsTotalCosto(items)
        let totDescuentos = valorTotalDescuentos(descuentosIt)
        setTotalItems(totItems)
        setTotalDescuentos(totDescuentos)
        setTotalCostoItems(totCosto)

    }, []);
    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                sx={{pr: sCell ? 1 : 0}}
                spacing={2}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={AccountCircle} nombre={'Nombre'} dato={'nombre'}
                                  requerido={'El nombre es requerido**'} {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={PhoneAndroid} nombre={'Celular'} dato={'celular'}
                                  requerido={'El celular es requerido**'} {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={Badge} nombre={'Nit/Cc'} dato={'cc'}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={Call} nombre={'Tel Secundario'} dato={'telefono'}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={LocationCity} nombre={'Ciudad'} dato={'ciudad'}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={Email} nombre={'Correo Electronico'} dato={'correo'}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto nombre={'Notas adicionales'} dato={'notas'} lineas={3}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Typography sx={{color: theme.palette.primary.main}}>Incrementos</Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresarObjeto editable={props.editable} />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end', px: 1}}>
                    <Typography sx={{borderColor: '#00000080'}}>Total Items: <span
                        style={{pl: 5, fontWeight: 600}}>{formatoMoneda(totalItems)}</span></Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end', px: 1, marginTop: -2}}>
                    <Typography sx={{borderBottom: 0, borderColor: '#00000080'}}>Incrementos: <span
                        style={{pl: 5, fontWeight: 600}}>{formatoMoneda(totalIncrementos)}</span></Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end', px: 1, marginTop: -2}}>
                    <Typography sx={{borderBottom: 1, borderColor: '#00000080'}}>Descuentos: <span
                        style={{pl: 5, fontWeight: 600}}>{formatoMoneda(totalDescuentos * -1)}</span></Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end', px: 1, marginTop: -1}}>
                    <Typography sx={{borderColor: '#00000080'}}>Sub Total: <span
                        style={{
                            pl: 5,
                            fontWeight: 600
                        }}>{formatoMoneda(totalItems + totalIncrementos - totalDescuentos)}</span></Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end', px: 1, marginTop: -2}}>
                    <Typography sx={{borderColor: '#00000080', borderBottom: 1}}>Iva 19%: <span
                        style={{
                            pl: 5,
                            fontWeight: 600
                        }}>{formatoMoneda(obtenerIva(totalItems + totalIncrementos - totalDescuentos))}</span></Typography>
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end', px: 1, marginTop: -1}}>
                    <Typography sx={{borderColor: '#00000080'}}>Total Cotizacion: <span
                        style={{
                            pl: 5,
                            fontWeight: 600
                        }}>{formatoMoneda(total)}</span></Typography>
                </Grid>


            </Grid>
        </form>
    )

}
export default Formulario_Cotizacion_Incrementos