/**************************************************
 * Nombre:       Logo Titulo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Font, StyleSheet, Text, View} from '@react-pdf/renderer';
import f400 from '../Recursos/Poppins-Regular.ttf'
import f500 from '../Recursos/Poppins-Medium.ttf'
import f600 from '../Recursos/Poppins-SemiBold.ttf'
import f700 from '../Recursos/Poppins-Bold.ttf'
import f800 from '../Recursos/Poppins-Black.ttf'
import {formatoMoneda} from "../../../Utilidades/formatoMoneda";


const Tabla_Items = ({datos}) => {
    Font.register({
        family: 'Poppins',
        fonts: [
            {
                src: f400,
                fontWeight: 400
            },
            {
                src: f500,
                fontWeight: 500
            },
            {
                src: f600,
                fontWeight: 600
            },
            {
                src: f700,
                fontWeight: 700
            },
            {
                src: f800,
                fontWeight: 800
            },
        ]
    })


    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#fff',
            paddingTop: 32,
            paddingBottom: 32,
            paddingLeft: 32,
            paddingRight: 40
        },
        gitc: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            alignContent: 'flex-start',
        },
        git: {
            flexDirection: 'row',
            alignItems: 'flex-start',
            alignContent: 'flex-start',
        },
        gitf: {
            flexDirection: 'row',
            alignItems: 'flex-end',
            alignContent: 'flex-end',
        },
        tTitulo: {
            fontSize: 20,
            fontFamily: 'Poppins',
            fontWeight: 700,
            color: '#0C4F80'
        },
        tMed: {
            fontSize: 10,
            fontFamily: 'Poppins',
            fontWeight: 600,
        },
        tNor: {
            fontSize: 10,
            fontFamily: 'Poppins',
            fontWeight: 400,
        }


    });

    const CabeceraTabla = () => {

        return (
            <View style={styles.git}>

                <View style={{...styles.git, width: '5%'}}>
                    <Text style={styles.tMed}>Cant</Text>
                </View>

                <View style={{...styles.git, width: '55%'}}>
                    <Text style={{...styles.tMed, textAlign: 'center', width: '100%'}}>Descripción</Text>
                </View>

                <View style={{...styles.git, width: '20%'}}>
                    <Text style={{...styles.tMed, textAlign: 'right', width: '100%'}}>V.unitario</Text>
                </View>

                <View style={{...styles.git, width: '20%'}}>
                    <Text style={{...styles.tMed, textAlign: 'right', width: '100%'}}>V.total</Text>
                </View>

            </View>
        )
    }

    const LineaDatos = ({item}) => {

        return (
            <View style={{...styles.git, borderBottom: 0.5, paddingTop: 6, paddingBottom: 6}}>

                <View style={{...styles.git, width: '5%'}}>
                    <Text style={{...styles.tNor, textAlign: 'center', width: '100%'}}>{item.cantidad}</Text>
                </View>

                <View style={{...styles.git, width: '55%'}}>
                    <Text style={{...styles.tNor, width: '100%', paddingLeft: 10}}>{item.nombre}</Text>
                </View>

                <View style={{...styles.git, width: '20%'}}>
                    <Text style={{...styles.tNor, textAlign: 'right', width: '100%', paddingLeft: 10}}>{formatoMoneda(item.valor)}</Text>
                </View>

                <View style={{...styles.git, width: '20%'}}>
                    <Text style={{...styles.tNor, textAlign: 'right', width: '100%', paddingLeft: 10}}>
                        {formatoMoneda(item.cantidad * item.valor)}
                    </Text>
                </View>

            </View>
        )
    }


    return (

        <View style={styles.gitc}>

            <CabeceraTabla/>

            {datos && datos.items && datos.items.map((item, index ) => {
                return(
                    <LineaDatos item={item} key={`da${index}`}/>
                )
            })}






        </View>


    )
}

export default Tabla_Items;
