/**************************************************
 * Nombre:       Formulario_Login
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {Business, Email, Password} from "@mui/icons-material";

const Formulario_Login = ({...props}) => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <IngresoTexto Icono={Email} nombre={'Correo'} dato={"correo"} {...props}
                              requerido={'El correo es requerido*'}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <IngresoTexto Icono={Password} nombre={'Constraseña'} dato={"pass"} {...props} type={'password'}
                              requerido={'El correo es requerido*'}/>
            </Grid>


        </Grid>
    )

}
export default Formulario_Login