/**************************************************
 * Nombre:       Home
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import mundo from '../../Recursos/mundo_energia.png'
import escudo from '../../Recursos/escudo_energia.png'
import camara from '../../Recursos/camara_seguridad.png'
import panel from '../../Recursos/panel_solar.png'
import {theme} from "../../Tema";
import {FiberManualRecord} from "@mui/icons-material";
import Portada from "../Componentes/Portada";
import mano from '../../Recursos/mano_energia.png'
import Mesa_Trabajo from "../Componentes/Mesa_Trabajo";

const Home = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const sTab = useMediaQuery(theme.breakpoints.only('sm'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Portada/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: masSM ? -8 :  12}}>
                    <Mesa_Trabajo/>
                </Grid>


            </Grid>


        </Grid>
    )

}
export default Home

