/**************************************************
 * Nombre:       Indicadores
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, useMediaQuery} from "@mui/material";
import IndicadorRendimiento from "./Coponentes/IndicadorRendimiento";
import {theme} from "../../../Tema";
import IndicadorDosValores from "./Coponentes/IndicadorDosValores";
import IndicadorNoPorcentaje from "./Coponentes/IndicadorNoPorcentaje";
import IndicadorNoMoneda from "./Coponentes/IndicadorNoMoneda";

const Indicadores = ({valores, dir = 'flex-start'}) => {
    const masSM = useMediaQuery(theme.breakpoints.up("md"))

    return (
        <Grid
            container
            direction="row"
            justifyContent={dir}
            alignItems="flex-start"
            sx={{py: 2}}
            spacing={1}
        >

            {valores.map((it, index) => {

                switch (it.tipo) {
                    case 'rendimiento' :
                        return (
                            <IndicadorRendimiento
                                key={`rew${index}`}
                                titulo={it.titulo}
                                valorAnterior={it.valorAnterior}
                                valorActual={it.valorActual}
                                detalle={it.detalle}
                                ceparador={(index + 1) % (masSM ? 4 : 2) === 0}
                                masSM={masSM}
                            />
                        )

                        break
                    case 'valores' :
                        return (
                            <IndicadorDosValores
                                key={`rew${index}`}
                                titulo={it.titulo}
                                valorAnterior={it.valorAnterior}
                                valorActual={it.valorActual}
                                detalle={it.detalle}
                                ceparador={(index + 1) % (masSM ? 4 : 2) === 0}
                                masSM={masSM}
                            />
                        )

                    break

                    case 'noporcentaje' :
                        return (
                            <IndicadorNoPorcentaje
                                key={`rew${index}`}
                                titulo={it.titulo}
                                valorAnterior={it.valorAnterior}
                                valorActual={it.valorActual}
                                detalle={it.detalle}
                                ceparador={(index + 1) % (masSM ? 4 : 2) === 0}
                                masSM={masSM}
                            />
                        )

                        break

                    case 'nomoneda' :
                        return (
                            <IndicadorNoMoneda
                                key={`rew${index}`}
                                titulo={it.titulo}
                                valorAnterior={it.valorAnterior}
                                valorActual={it.valorActual}
                                detalle={it.detalle}
                                ceparador={(index + 1) % (masSM ? 4 : 2) === 0}
                                masSM={masSM}
                            />
                        )

                        break

                    default :
                        return (
                            <IndicadorRendimiento
                                key={`rew${index}`}
                                titulo={it.titulo}
                                valorAnterior={it.valorAnterior}
                                valorActual={it.valorActual}
                                detalle={it.detalle}
                                ceparador={(index + 1) % (masSM ? 4 : 2) === 0}
                                masSM={masSM}
                            />
                        )

                }


            })}


        </Grid>
    )

}
export default Indicadores    