/**************************************************
 * Nombre:       Seccion_Catalogo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     24 Hr
 **************************************************/
import {Button, Grid, useMediaQuery} from "@mui/material";
import {useEffect, useState} from "react";
import {Edit, InventoryOutlined, Outbound, PointOfSale, TaskAlt,} from "@mui/icons-material";
import {theme} from "../../Tema";
import TituloNotificaciones from "../../Modulo_dashboard/ComponentesSecundarios/TituloNotificaciones";
import TablaBasica from "../../Modulo_tablas/Componentes/TablaBasica";
import {useObtenerColleccionTabla} from "../../Servicios/BD/useObtenerColleccionTabla";
import Dialogo_Cotizacion_Ingresar_Items from "../Dialogos/Dialogo_Cotizacion_Ingresar_Items";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import {contarPropiedadValor} from "../../Servicios/BD/Contadores/contarPropiedadValor";
import {contarPropiedadSumaMes} from "../../Servicios/BD/Contadores/contarPropiedadSumaMes";
import {obtenerMesYearAnterior} from "../../Modulo_Graficas/Utilidades/obtenerMesYearAnterior";
import {adaptador_indicador} from "../../Modulo_Graficas/Adaptadores/adaptador_indicador";
import Indicadores from "../../Modulo_Graficas/Componentes/Indicadores/Indicadores";
import {ESTADOSCOTIZACION, ESTADOSORDEN} from "../../Constantes";
import {sumarPropiedadColeccion} from "../../Servicios/BD/Contadores/sumarPropiedadColeccion";
import Dialogo_Orden_Abonos from "../Dialogos/Dialogo_Orden_Abonos";
import Dialogo_Orden_Aprobar from "../Dialogos/Dialogo_Orden_Aprobar";
import {comprimirNumero} from "../../Modulo_Graficas/Utilidades/comprimirNumero";
import {formatoMoneda} from "../../Utilidades/formatoMoneda";
import Dialogo_Cartera_Cerrar from "../Dialogos/Dialogo_Cartera_Cerrar";
import Dialogo_Cartera_Abonos from "../Dialogos/Dialogo_Cartera_Abonos";


const Seccion_Cartera = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [numProductos, setNumProductos] = useState(0)
    const [recargar, setRecargar] = useState(false)
    const {props, cargarNuevamente} = useObtenerColleccionTabla({
        coleccion: 'carteras',
        filtroInicial: 'fecha',
        nombreEstado: 'estado',
    })
    const {Dialogo, abrir} = useDialogo({
        Componente: Dialogo_Cartera_Abonos,
        realizado: () => setRecargar(!recargar),
        titulo: 'Abonos a orden de servicio'
    })
    const {Dialogo: DialogoAprobar, abrir: abrirAprobar} = useDialogo({
        Componente: Dialogo_Cartera_Cerrar,
        realizado: () => setRecargar(!recargar),
        titulo: 'Estado'
    })

    const [valoresPedidos, setValoresPedidos] = useState([])

    const botones = [
        <Button
            color={'primary'}
            onClick={() => abrir()}
            startIcon={<InventoryOutlined/>}
            variant={'contained'}>
            Nueva
        </Button>
    ]

    const manejardorClicks = (row, id) => {

        switch (id) {
            case 'abonar' :
                abrir(row)
                break
            case 'completar' :
                abrirAprobar(row)
                break

        }

    }
    const llenarValores = () => {

        let totalCartera = 0
        let totalAbonos = 0

        sumarPropiedadColeccion({coleccion: 'carteras', propiedad: 'total'}).then((dox) => {
            if (dox.res) {
                totalCartera = dox.data

                sumarPropiedadColeccion({coleccion: 'carteras', propiedad: 'totalAbonos'}).then((des) => {
                    if (des.res) {
                        totalAbonos = des.data


                        let recaudoActual = adaptador_indicador({
                            titulo: 'Recaudo Potencial',
                            valorActual: formatoMoneda(totalCartera - totalAbonos),
                            valorAnterior: comprimirNumero(totalAbonos),
                            detalle: 'ya recaudado',
                            tipo: 'nomoneda'
                        })


                        setValoresPedidos([recaudoActual])


                    }
                })

            }
        })

    }

    useEffect(() => {

        contarPropiedadValor({coleccion: 'carteras', propiedad: 'estado', valor: 'Nueva'}).then((dox) => {
            if (dox.res) {
                setNumProductos(dox.data)
            }
        })

        llenarValores()

        cargarNuevamente()

    }, [recargar]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>

            <DialogoAprobar/>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <TituloNotificaciones titulo={'Cartera'} textoComplementario={'nuevas'}
                                      textoResaltado={`${numProductos} cartera para cobrar`} botones={botones}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end'}}>
                <Indicadores valores={valoresPedidos} dir={'flex-end'}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 5}}>
                <TablaBasica
                    filtros={filtros}
                    datosCabecera={sCell ? datosCabeceraCell : datosCabeceraPc}
                    {...props}
                    click={manejardorClicks}
                    estados={ESTADOSORDEN}
                    botonesTabla={botonesTabla}
                />
            </Grid>


        </Grid>
    )

}
export default Seccion_Cartera

const filtros = [
    {
        nombre: 'Nombre',
        propiedad: 'nombre',

    },
    {
        nombre: 'Ciudad',
        propiedad: 'ciudad',

    },
    {
        nombre: 'Mas Reciente',
        propiedad: 'fecha',
        direccion: 'desc'

    }
]

const datosCabeceraPc = [
    {
        nombre: 'Num',
        direccion: 'center',
        propiedad: 'consecutivo',
        tipo: 'numero'
    },
    {
        nombre: 'Fecha',
        direccion: 'left',
        propiedad: 'fecha',
        tipo: 'fecha'
    },
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nombre',
    },
    {
        nombre: 'Estado',
        direccion: 'right',
        propiedad: 'estado',

    }, {
        nombre: 'Total',
        direccion: 'right',
        propiedad: 'total',
        tipo: 'moneda'
    }, {
        nombre: 'Abonos',
        direccion: 'right',
        propiedad: 'totalAbonos',
        tipo: 'moneda'

    },

]

const datosCabeceraCell = [
    {
        nombre: 'Num',
        direccion: 'center',
        propiedad: 'consecutivo',
        tipo: 'numero'
    },
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nombre',
    },
    {
        nombre: 'Estado',
        direccion: 'right',
        propiedad: 'estado',

    }
]

const botonesTabla = [
    {
        nombre: 'Abonos',
        icono: PointOfSale,
        id: 'abonar'
    },
    {
        nombre: 'Completar',
        icono: TaskAlt,
        id: 'completar'
    },
]

