/**************************************************
 * Nombre:       Portada
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import {theme} from "../../Tema";
import {FiberManualRecord} from "@mui/icons-material";
import mundo from "../../Recursos/mundo_energia.png";
import escudo from "../../Recursos/escudo_energia.png";
import panel from "../../Recursos/panel_solar.png";
import camara from "../../Recursos/camara_seguridad.png";
import {irUrl} from "../../Utilidades/irUrl";
import {LINKWASS} from "../../Constantes";
import {useScrolY} from "../../Modulo_Efectos_Scroll/Hooks/useScrolY";

const Portada = () => {
    const {y, motion} = useScrolY({velocidad: -0.1})
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const sTab = useMediaQuery(theme.breakpoints.only('sm'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 10, zIndex: 1}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{
                        justifyContent: 'flex-start',
                        borderLeft: 1,
                        borderColor: theme.palette.primary.main
                    }}>
                        <Typography sx={{color: theme.palette.primary.main, px: 1}}>Nosotros y nuestros
                            servicios</Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{
                        justifyContent: 'flex-start',
                        marginTop: sCell ? 2 : 4
                    }}>
                        <Typography sx={{
                            color: theme.palette.primary.main,
                            fontSize: sCell ? 30 : 42,
                            fontWeight: sCell ? 600 : 700,
                            lineHeight: sCell ? 1.2 : 1.1,
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: sCell ? 6 : 3,
                            textOverflow: "ellipsis",

                        }}>
                            <span style={{paddingRight: 8, color: '#fff'}}>Soluciones</span>
                            en Electricidad,
                            telecomunicaciones,
                            calidad y eficiencia energética
                        </Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{
                        justifyContent: 'flex-start',
                        marginTop: 4
                    }}>
                        <Typography sx={{
                            color: '#fff',
                            fontSize: 16,
                            fontWeight: 400,
                        }}>

                            Diseñamos, implementamos y mantenemos redes eléctricas, redes de telecomunicaciones;
                            seguridad electrónica, calidad y eficiencia energética con los mas altos estándares de
                            calidad
                            y servicio
                        </Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{justifyContent: sCell ? 'center' : 'flex-start', marginTop: 4}}>
                        <Button
                            onClick={() => irUrl(LINKWASS)}
                            startIcon={<FiberManualRecord color={'info'}/>} color={'action'}
                            sx={{color: '#000C22', py: 1, px: sCell ? 8 : 4}}>
                            Contactar
                        </Button>
                    </Grid>

                </Grid>

            </Grid>

            <Grid item container lg={6} sm={12} xs={12}
                  sx={{justifyContent: sTab ? 'center' : 'flex-start', zIndex: 2, marginTop: masSM ? 0 : 6}}>
                <motion.img src={mundo} width={sTab ? '50%' : '110%'} height={'auto'}
                            alt={'Energia limpia EyT soluciones'}
                            style={{backgroundColor: '#000C22', y: y, margin: -20}}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: -24, zIndex: 4}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{backgroundColor: '#038BFF20', px: sCell ? 4 : 5, py: 4, backdropFilter: 'blur(5px)'}}
                >

                    <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'flex-start', pl: sCell ? 0 : 4}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >

                            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                <Typography
                                    sx={{color: '#fff', fontSize: sCell ? 28 : 34, fontWeight: 700, lineHeight: 1.1}}>Eyt
                                    Soluciones
                                    S.A.S</Typography>
                            </Grid>

                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: 'flex-start', marginTop: 1}}>
                                <Typography sx={{color: '#fff', fontSize: 16, fontWeight: 400}}>
                                    Nuestra empresa se compromete con la excelencia en la prestación de servicios,
                                    contamos con un equipo altamente capacitado y siempre a la vanguardia,
                                    ofreciendo soluciones eficientes a sus necesidades.
                                </Typography>
                            </Grid>


                        </Grid>
                    </Grid>

                    <Grid item container lg={6} sm={6} xs={12}
                          sx={{justifyContent: sCell ? 'center' : 'flex-end', marginTop: sCell ? 3 : 0}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent={masSM ? 'flex-end' : "center"}
                            alignItems="center"
                            spacing={sCell ? 4 : 0}
                        >

                            {iconos.map((item, index) => {
                                return (
                                    <Grid item key={`as${index}`} container lg={3} sm={6} xs={4}
                                          sx={{justifyContent: 'center'}}>
                                        <img src={item} width={'auto'} height={sCell ? 60 : 74} alt={index}/>
                                    </Grid>
                                )
                            })}

                        </Grid>
                    </Grid>

                </Grid>
            </Grid>

        </Grid>
    )

}
export default Portada

const iconos = [
    escudo, panel, camara
]