export const obtenerMesYearAnterior = ({year, mes}) => {

    if (mes < 1) {
        year--
    }

    mes--

    return ({year: year, mes: mes})

}