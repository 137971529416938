/**************************************************
 * Nombre:       Formulario_Persona
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography, useMediaQuery} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {AccountCircle, Badge, Call, Email, LocationCity, PhoneAndroid} from "@mui/icons-material";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import {React, useEffect, useState} from "react";
import {obtenerCol} from "../../Servicios/BD/obtenerCol";
import {useIngresarObjeto} from "../../Modulo_formularios/Hooks/useIngresarObjeto";
import Formulario_Item_Lista from "./Formulario_Item_Lista";
import Tarjeta_Item_Lista from "../Tarjetas/Tarjeta_Item_Lista";
import {formatoMoneda} from "../../Utilidades/formatoMoneda";
import {obtenerIva} from "../Utilidades/obtenerIva";
import {theme} from "../../Tema";
import {valorItemsTotal} from "../Utilidades/valorItemsTotal";
import {valorItemsTotalCosto} from "../Utilidades/valorItemsTotalCosto";
import {valorTotalIncrementos} from "../Utilidades/valorTotalIncrementos";
import {valorTotalDescuentos} from "../Utilidades/valorTotalDescuentos";

const Formulario_Pedido = ({...props}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [clientes, setClientes] = useState([])
    const [nomClientes, setNomClientes] = useState([])
    const {IngresarObjeto} = useIngresarObjeto({
        props: props,
        nombre: 'Ingresar Items',
        Formulario: Formulario_Item_Lista,
        dato: 'items',
        Tarjeta: Tarjeta_Item_Lista
    })
    const [total, setTotal] = useState(0)
    const [subTotal, setSubTotal] = useState(0)
    const [totalItems, setTotalItems] = useState(0)
    const [totalCostoItems, setTotalCostoItems] = useState(0)
    const [totalIncrementos, setTotalIncrementos] = useState(0)
    const [totalDescuentos, setTotalDescuentos] = useState(0)


    useEffect(() => {

        let arr = props.props.getValues('items')

        // console.log(arr)

        if (arr && arr.length > 0) {
            let totItems = 0
            let cos = 0
            for (let i = 0; i < arr.length; i++) {
                totItems = totItems + (arr[i].cantidad * arr[i].valor)
                cos = cos + (arr[i].cantidad * arr[i].costo)
            }


            let subTot = totItems + totalIncrementos - totalDescuentos
            let iva = obtenerIva(subTot)
            let tot = subTot + iva
            let totCos = iva + cos

            setTotalItems(totItems)
            setTotal(tot)
            setSubTotal(subTot)

            props.props.setValue('total', tot)
            props.props.setValue('costo', totCos)

            if (totalIncrementos === 0 || totalDescuentos === 0){
                obtenerTotales()
            }


        } else {
            setTotal(0)
            setSubTotal(0)
            props.props.setValue('total', 0)
            props.props.setValue('costo', 0)
        }


    }, [props.props.watch(['items'])])


    useEffect(() => {


        let nombre = props.props.getValues('nombre')

        if (nombre) {
            let index = clientes.findIndex((e) => e.nombre === nombre)

            let cliente = clientes[index]
            if (index > -1 && cliente) {
                // console.log(cliente)
                props.props.setValue('cc', cliente.cc)
                props.props.setValue('celular', cliente.celular)
                props.props.setValue('direccion', cliente.direccion)
                props.props.setValue('idCliente', cliente.id)
                props.props.setValue('telefono', cliente.telefono)
                props.props.setValue('ciudad', cliente.ciudad)
                props.props.setValue('correo', cliente.correo)

            }

        }


    }, [props.props.watch('nombre')])


    const obtenerTotales = () => {
        let incre = props.props.getValues('incrementos')
        let desc = props.props.getValues('descuentos')
        let totIncre = valorTotalIncrementos(incre)
        let totDesc = valorTotalDescuentos(desc)
        setTotalIncrementos(totIncre)
        setTotalDescuentos(totDesc)
    }


    useEffect(() => {

        obtenerCol('clientes').then((dox) => {
            if (dox.res) {
                setClientes(dox.data)
                let nombres = dox.data.map((item) => (item.nombre))
                setNomClientes(nombres.sort())
            }
        })

        obtenerTotales()


    }, []);
    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{pr: sCell ? 1 : 0}}
                spacing={2}>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto nombre={'Justificacion'} dato={'justificacion'}
                                  opciones={nomClientes} {...props} lineas={2}


                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoSelect Icono={AccountCircle} nombre={'Cliente'} dato={'nombre'}
                                   opciones={nomClientes}
                                   requerido={'El nombre es requerido**'} {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={PhoneAndroid} nombre={'Celular'} dato={'celular'}
                                  requerido={'El celular es requerido**'} {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={Badge} nombre={'Nit/Cc'} dato={'cc'}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={Call} nombre={'Tel Secundario'} dato={'telefono'}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={LocationCity} nombre={'Ciudad'} dato={'ciudad'}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={Email} nombre={'Correo Electronico'} dato={'correo'}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto nombre={'Notas adicionales'} dato={'notas'} lineas={3}
                                  {...props}
                    />
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Typography>Items</Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresarObjeto editable={props.editable}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end', px: 1}}>
                    <Typography sx={{borderColor: '#00000080'}}>Total Items: <span
                        style={{pl: 5, fontWeight: 600}}>{formatoMoneda(totalItems)}</span></Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end', px: 1, marginTop: -2}}>
                    <Typography sx={{borderBottom: 0, borderColor: '#00000080'}}>Incrementos: <span
                        style={{pl: 5, fontWeight: 600}}>{formatoMoneda(totalIncrementos)}</span></Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end', px: 1, marginTop: -2}}>
                    <Typography sx={{borderBottom: 1, borderColor: '#00000080'}}>Descuentos: <span
                        style={{pl: 5, fontWeight: 600}}>{formatoMoneda(totalDescuentos * -1)}</span></Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end', px: 1, marginTop: -1}}>
                    <Typography sx={{borderColor: '#00000080'}}>Sub Total: <span
                        style={{
                            pl: 5,
                            fontWeight: 600
                        }}>{formatoMoneda(totalItems + totalIncrementos - totalDescuentos)}</span></Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end', px: 1, marginTop: -2}}>
                    <Typography sx={{borderColor: '#00000080', borderBottom: 1}}>Iva 19%: <span
                        style={{
                            pl: 5,
                            fontWeight: 600
                        }}>{formatoMoneda(obtenerIva(totalItems + totalIncrementos - totalDescuentos))}</span></Typography>
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end', px: 1, marginTop: -1}}>
                    <Typography sx={{borderColor: '#00000080'}}>Total Cotizacion: <span
                        style={{
                            pl: 5,
                            fontWeight: 600
                        }}>{formatoMoneda(total)}</span></Typography>
                </Grid>


            </Grid>
        </form>
    )

}
export default Formulario_Pedido