/**************************************************
 * Nombre:       Barra
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, useMediaQuery} from "@mui/material";
import {theme} from "../../Tema";
import BarraPc from "./BarraPc";
import BarraCell from "./BarraCell";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import Dialogo_Login from "../Dialogos/Dialogo_Login";

const Barra = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const {Dialogo, abrir} = useDialogo({Componente: Dialogo_Login})


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>

            {masSM ? <BarraPc abrir={abrir} /> : <BarraCell abrir={abrir} />}


        </Grid>
    )

}
export default Barra