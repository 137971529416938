/**************************************************
 * Nombre:       Pdf_Cotizacion
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Document, Font, Image, Page, StyleSheet, View} from '@react-pdf/renderer';
import f400 from './Recursos/Poppins-Regular.ttf'
import f500 from './Recursos/Poppins-Medium.ttf'
import f600 from './Recursos/Poppins-SemiBold.ttf'
import f700 from './Recursos/Poppins-Bold.ttf'
import f800 from './Recursos/Poppins-Black.ttf'
import Logo_Titulo from "./Componentes/Logo_Titulo";
import Datos_Cliente from "./Componentes/Datos_Cliente";
import Datos_Empresa from "./Componentes/Datos_Empresa";
import Tabla_Items from "./Componentes/Tabla_Items";
import Tabla_Descuentos from "./Componentes/Tabla_Descuentos";
import Notas_Cotizacion from "./Componentes/Notas_Cotizacion";
import Totales_Cotizacion from "./Componentes/Totales_Cotizacion";
import pie from './Recursos/pie.png'
import Tabla_Abonos from "./Componentes/Tabla_Abonos";


const Pdf_Cotizacion = ({datos, orden = false}) => {
    Font.register({
        family: 'Poppins',
        fonts: [
            {
                src: f400,
                fontWeight: 400
            },
            {
                src: f500,
                fontWeight: 500
            },
            {
                src: f600,
                fontWeight: 600
            },
            {
                src: f700,
                fontWeight: 700
            },
            {
                src: f800,
                fontWeight: 800
            },
        ]
    })


    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#fff',
            padding: 42
        },
        gitc: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            alignContent: 'flex-start',
        },
        git: {
            flexDirection: 'row',
            alignItems: 'flex-start',
            alignContent: 'flex-start',
        },
        gitf: {
            flexDirection: 'row',
            alignItems: 'flex-end',
            alignContent: 'flex-end',
        },
        tTitulo: {
            fontSize: 20,
            fontFamily: 'Poppins',
            fontWeight: 700,
            color: '#0C4F80'
        },
        tMed: {
            fontSize: 8,
            fontFamily: 'Poppins',
            fontWeight: 400,
        }


    });


    return (
        <Document>
            <Page size={'letter'} style={styles.page}>

                <View style={styles.gitc}>

                    <Logo_Titulo datos={datos} orden={orden}/>

                    <View style={{marginTop: 10}}/>

                    <Datos_Empresa/>

                    <View style={{marginTop: 20}}/>

                    <Datos_Cliente datos={datos}/>

                    <View style={{marginTop: 26}}/>

                    <Tabla_Items datos={datos}/>

                    <View style={{marginTop: 26}}/>

                    {/* retirada por solicitud del cliente
                    <Tabla_Descuentos datos={datos}/>
                    */}


                    <Notas_Cotizacion datos={datos}/>

                    {orden &&
                        <>
                            <View style={{marginTop: 26}}/>

                            <Tabla_Abonos datos={datos}/>
                        </>
                    }

                    <View style={{marginTop: 26}}/>

                    <Totales_Cotizacion datos={datos} orden={orden}/>

                    <View style={{marginTop: 26}}/>

                    <View>
                        <Image src={pie} style={{width: 550, height: 114}}/>
                    </View>


                </View>


            </Page>
        </Document>
    )
}

export default Pdf_Cotizacion;
