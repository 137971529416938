/**************************************************
 * Nombre:       Tarjeta_Item_Lista
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {ButtonBase, Grid, Typography} from "@mui/material";
import {formatoMoneda} from "../../Utilidades/formatoMoneda";
import {obtenerFecha} from "../../Utilidades/obtenerFecha";

const Tarjeta_Abono = ({item, click, index}) => {

    return (
        <ButtonBase
            onClick={() => click(item, index)}
            sx={{width: '100%', textAlign: 'left'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-end"
                sx={{px: 1, borderBottom: 1, borderColor: '#00000080', py: 0.5}}
            >

                <Grid item container lg={8} sm={8} xs={8} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Typography sx={{fontSize: 10, fontWeight: 500}}>{obtenerFecha(item.fecha)}</Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Typography sx={{fontSize: 14, color: '#00000080'}}>{item.descripcion}</Typography>
                        </Grid>

                    </Grid>

                </Grid>

                <Grid item container lg={4} sm={4} xs={4} sx={{justifyContent: 'flex-end'}}>
                    <Typography>{formatoMoneda(item.valorAbono)}</Typography>
                </Grid>


            </Grid>
        </ButtonBase>

    )

}
export default Tarjeta_Abono