import {collection, query, where, getCountFromServer, getDocs, sum, getAggregateFromServer,} from 'firebase/firestore'
import {fire} from "../../../fire";

export const sumarPropiedadColeccion = ({coleccion, propiedad}) => {
    // 0 es enero


    let col = collection(fire, coleccion)



    return new Promise(resolve => {
        getAggregateFromServer(col, {suma: sum(propiedad)}).then((dox) => {
            return resolve({res: true, data: dox.data().suma})
        }).catch((err) => {
            return resolve({res: false, data: 0})
        })
    })


}