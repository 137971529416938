/**************************************************
 * Nombre:       Dialogo_Persona
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, useMediaQuery} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {Engineering, InventoryOutlined, LocalShipping} from "@mui/icons-material";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_redondo.svg'
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import Formulario_Cotizacion_Descuento from "../Formularios/Formulario_Cotizacion_Descuento";
import {theme} from "../../Tema";
import Formulario_Orden_Abonos from "../Formularios/Formulario_Orden_Abonos";

const Dialogo_Cartera_Abonos = ({datos, cerrar, realizado}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const {props, obtenerEntidad, setEntidad} = useFormulario({valoresDefecto: datos})
    const {abrirCargador, cerrarCargador, Cargador} = useLoaders({logo: logo})

    const guardarDatos = () => {
        obtenerEntidad().then((entidad) => {
            abrirCargador('Subiendo datos')
            let obj = entidad
            if (!obj.fecha) {
                obj.fecha = new Date().getTime()
            }

            if (!obj.estado) {
                obj.estado = 'Nueva'
            }

            guardarDoc('carteras', obj).then((dox) => {
                cerrarCargador()
                if (dox.res) {
                    cerrar()
                    realizado()
                    alert('Cambios guardados con exito')
                } else {
                    alert('Ups algo paso, por favor intentalo nuevamente')
                }
            })
        })

    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"

        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Formulario_Orden_Abonos props={props} editable={datos && datos.estado !== 'Cerrada'}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                <Button
                    disabled={datos && datos.estado === 'Cerrada'}
                    onClick={() => guardarDatos()}
                    startIcon={<Engineering/>}
                >{datos ? 'Actualizar Orden' : 'Crear Orden'}</Button>

            </Grid>


        </Grid>
    )

}
export default Dialogo_Cartera_Abonos