export const porcentajeDosValore = (valorActual, valorAnterior) => {

    if (valorActual === 0){
        valorActual = 1
    }


    // que porcentaje representa la diferencia con respecto al primer valor
    let porsentaje = (100 * valorAnterior) / valorActual

    return porsentaje.toFixed(2)

}