/**************************************************
 * Nombre:       Dialogo_Login
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import logo from '../../Recursos/logo_redondo.svg'
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import Formulario_Login from "../Formularios/Formulario_Login";
import {funIngresarCorreoPass} from "../../Servicios/Auth/funIngresarCorreoPass";
import {funRecuperarContrasena} from "../../Servicios/Auth/funRecuperarContrasena";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import {theme} from "../../Tema";

const Dialogo_Login = ({cerrar}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const {props, obtenerEntidad} = useFormulario({valoresDefecto: {}})
    const {abrirCargador, Cargador, cerrarCargador} = useLoaders({logo: logo})

    const ingresar = () => {
        obtenerEntidad().then((entidad) => {
            abrirCargador('Ingresando al panel de control')
            funIngresarCorreoPass(entidad.correo, entidad.pass).then((dox) => {
                if (dox.res) {
                    cerrarCargador()
                }
            })
        })

    }

    const recuperar = () => {
        obtenerEntidad().then((entidad) => {
            abrirCargador('Verificando correo de usuario')
            funRecuperarContrasena(entidad.correo).then((dox) => {
                if (dox.res) {
                    cerrarCargador()
                    alert('Las instrucciones han sido enviadas al correo ' + entidad.correo)
                } else {
                    cerrarCargador()
                    alert(dox.message)
                }
            })
        })
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{p: sCell ? 2 : 4}}
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <img src={logo} width={150} height={'auto'}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 8}}>
                <Formulario_Login props={props}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                <ButtonBase
                    onClick={() => recuperar()}
                >
                    <Typography>Olvide mi contraseña</Typography>
                </ButtonBase>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                <Button
                    onClick={() => ingresar()}
                    sx={{px: 6, py: 1}}>
                    Ingresar
                </Button>
            </Grid>


        </Grid>
    )

}
export default Dialogo_Login