/**************************************************
 * Nombre:       BarraPc
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, IconButton} from "@mui/material";
import logo from '../../Recursos/logo_oscuro.svg'
import {REDES} from "../../Constantes";
import {irUrl} from "../../Utilidades/irUrl";

const BarraPc = ({abrir}) => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{py: 3}}
        >

            <Grid item container lg={7} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <img src={logo} width={240} height={'auto'}/>
            </Grid>

            <Grid item container lg={5} sm={12} xs={12} sx={{justifyContent: 'flex-end'}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >

                    {REDES.map((item, index) => {
                        return (
                            <Grid item container lg={1} sm={12} xs={12} sx={{justifyContent: 'flex-end'}}>
                                <IconButton
                                    onClick={() => irUrl(item.url)}
                                >
                                    <item.icono sx={{fill: '#fff', height: 32, width: 32}}/>
                                </IconButton>
                            </Grid>
                        )
                    })}

                    <Grid item container lg={3} sm={12} xs={12} sx={{justifyContent: 'flex-end'}}>
                        <Button
                            onClick={() => abrir()}
                            sx={{
                                background: 'linear-gradient(87.37deg, #0D5080 6.26%, #0F94D5 70.27%)'
                            }}>
                            Ingresar
                        </Button>
                    </Grid>


                </Grid>
            </Grid>


        </Grid>
    )

}
export default BarraPc    