/**************************************************
 * Nombre:       Seccion_Catalogo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     24 Hr
 **************************************************/
import {Button, Grid, useMediaQuery} from "@mui/material";
import {useEffect, useState} from "react";
import {Edit, PersonAdd,} from "@mui/icons-material";
import {contarColeccion} from "../../Servicios/BD/Contadores/contarColeccion";
import TituloNotificaciones from "../../Modulo_dashboard/ComponentesSecundarios/TituloNotificaciones";
import TablaBasica from "../../Modulo_tablas/Componentes/TablaBasica";
import {useObtenerColleccionTabla} from "../../Servicios/BD/useObtenerColleccionTabla";
import Dialogo_Clientes from "../Dialogos/Dialogo_Clientes";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import {theme} from "../../Tema";
import {contarPropiedadValor} from "../../Servicios/BD/Contadores/contarPropiedadValor";
import {ESTADOSPQRS, ESTADOSTICKETS} from "../../Constantes";
import Dialogo_Pqrs from "../Dialogos/Dialogo_Pqrs";
import Dialogo_Tickets from "../Dialogos/Dialogo_Tickets";


const Seccion_Tickets = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [numProductos, setNumProductos] = useState(0)
    const [recargar, setRecargar] = useState(false)
    const {props, cargarNuevamente,} = useObtenerColleccionTabla({
        coleccion: 'tickets',
    })
    const {Dialogo, abrir} = useDialogo({
        Componente: Dialogo_Tickets,
        realizado: () => setRecargar(!recargar),
        titulo: 'Detalle de Ticket'
    })


    const botones = []
    const manejardorClicks = (row, id) => {

        switch (id) {
            case 'editar' :
                abrir(row)
                break

        }

    }

    useEffect(() => {

        contarPropiedadValor({coleccion: 'tickets', propiedad: 'estado', valor: 'Nuevo'}).then((dox) => {
            if (dox.res) {
                setNumProductos(dox.data)
            }
        })


        cargarNuevamente()


    }, [recargar]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <TituloNotificaciones titulo={'Tickets Helpdesk'} textoComplementario={'nuevos'}
                                      textoResaltado={`${numProductos} Tickets`} botones={botones}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 5}}>
                <TablaBasica
                    filtros={filtros}
                    datosCabecera={sCell ? datosCabeceraCell : datosCabeceraPc}
                    {...props}
                    click={manejardorClicks}
                    estados={ESTADOSTICKETS}
                    botonesTabla={botonesTabla}
                />
            </Grid>


        </Grid>
    )

}
export default Seccion_Tickets

const filtros = [
    {
        nombre: 'Empresa',
        propiedad: 'nombre',

    },
    {
        nombre: 'Persona',
        propiedad: 'persona',

    },
    {
        nombre: 'Mas Reciente',
        propiedad: 'fecha',
        direccion: 'desc'

    }
]

const datosCabeceraPc = [
    {
        nombre: 'Fecha',
        direccion: 'left',
        propiedad: 'fecha',
        tipo: 'fecha'
    },
    {
        nombre: 'Empresa',
        direccion: 'left',
        propiedad: 'nombre',

    },
    {
        nombre: 'Persona',
        direccion: 'left',
        propiedad: 'persona',

    },
    {
        nombre: 'Tipo',
        direccion: 'left',
        propiedad: 'tipo',

    },
    {
        nombre: 'Estado',
        direccion: 'right',
        propiedad: 'estado',

    }
]

const datosCabeceraCell = [
    {
        nombre: 'Fecha',
        direccion: 'left',
        propiedad: 'fecha',
        tipo: 'fecha'
    },
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nombre',

    },
    {
        nombre: 'Estado',
        direccion: 'right',
        propiedad: 'estado',

    }
]

const botonesTabla = [
    {
        nombre: 'Editar',
        icono: Edit,
        id: 'editar'
    }
]
