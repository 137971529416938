import {Facebook, WhatsApp} from "@mui/icons-material";

export const LINKWASS = 'https://api.whatsapp.com/send?phone=573168892075&text=Hola%20deseo%20informacion'

export const REDES = [
    {
        nombre: 'facebook',
        url: 'https://www.facebook.com/search/top?q=eyt%20soluciones%2Fjohn%20ignacio%20rosero',
        icono: Facebook
    },
    {
        nombre: 'whatsapp',
        url: LINKWASS,
        icono: WhatsApp
    },
]
export const ESTADOSCOTIZACION = ['Nueva', 'Seguimiento', 'Archivada', 'Aplazada']

export const ESTADOSORDEN = ['Nueva', 'Ejecutandose', 'Aplazada']

export const ESTADOSCARTERA = ['Nueva', 'Seguimiento', 'Aplazada']

export const ESTADOSPQRS = ['Nueva', 'Atendida', 'Archivada']

export const ESTADOSTICKETS = ['Nuevo', 'Atendido', 'Seguimiento', 'Archivado']

export const TIPONOVEDAD = ['Asistencia en manejo', 'Reporte daño', 'Otro']

export const TIPOPQRS = ['Peticion', 'Queja', 'Reclamo', 'Sugerencia']

