/**************************************************
 * Nombre:       Formulario_Clientes
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Divider, Grid, useMediaQuery} from "@mui/material";
import {
    AccountCircle,
    Badge,
    Call,
    Email,
    Factory, Flag,
    LocationCity, Password,
    PhoneAndroid,
    Store,
    WhatsApp
} from "@mui/icons-material";
import {useEffect, useState} from "react";
import {obtenerCol} from "../../Servicios/BD/obtenerCol";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {theme} from "../../Tema";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import {ESTADOSPQRS, TIPOPQRS} from "../../Constantes";
import IngresoImagenCropLista from "../../Modulo_formularios/Componentes/IngresoImagenCropLista";


const Formulario_Pqrs = ({...props}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [productos, setProductos] = useState([])

    useEffect(() => {
        obtenerCol('productos').then((dox) => {
            if (dox.res) {
                let arr = dox.data.map((it) => it.nombre)
                setProductos(arr)
            }

        })
    }, []);
    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{pr: sCell ? 1 : 0}}
                spacing={2}
            >
                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Factory} nombre={'Nombre de la empresa o persona'} dato={"nombre"} {...props}
                                  requerido={'El nombre es requerido*'} editable={false}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={AccountCircle} nombre={'Persona que radica la PQRS'}
                                  dato={"persona"} {...props} editable={false}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Email} nombre={'Correo electronico'} dato={"correo"} {...props}
                                  editable={false}
                                  requerido={'El correo es requerido*'}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={WhatsApp} nombre={'Numero WhatsApp'} dato={"wass"} {...props} editable={false}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoSelect Icono={Flag} nombre={'Tipo'} dato={"tipo"} {...props} opciones={TIPOPQRS}
                                   editable={false}
                                   requerido={'El tipo de novedad es requerido*'}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Password} nombre={'Mensaje'} dato={"mensaje"} {...props} editable={false}
                                  lineas={6}
                                  requerido={'El mensaje es requerido*'}/>
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoImagenCropLista dato={'anexos'} {...props} editable={false} lg={6} sm={6}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Divider sx={{width: '100%'}}/>
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoSelect Icono={Flag} nombre={'Estado Pqrs'} dato={"estado"} {...props} opciones={ESTADOSPQRS}
                                   requerido={'El tipo de novedad es requerido*'}
                    />
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Password} nombre={'Solicion'} dato={"solucion"} {...props}
                                  lineas={6}/>
                </Grid>


            </Grid>
        </form>
    )

}
export default Formulario_Pqrs