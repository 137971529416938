/**************************************************
 * Nombre:       Formulario_Persona
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {ButtonBase, Collapse, Grid, Typography, useMediaQuery} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {
    AccountCircle,
    Badge,
    Call,
    CurrencyExchange, Email,
    LocationCity,
    PhoneAndroid,
    RequestQuote,
    Widgets
} from "@mui/icons-material";
import IngresoDinero from "../../Modulo_formularios/Componentes/IngresoDinero";
import IngresoFecha from "../../Modulo_formularios/Componentes/IngresoFecha";
import {theme} from "../../Tema";
import {formatoMoneda} from "../../Utilidades/formatoMoneda";
import {obtenerIva} from "../Utilidades/obtenerIva";
import {React, useEffect, useState} from "react";
import {valorItemsTotal} from "../Utilidades/valorItemsTotal";
import {valorItemsTotalCosto} from "../Utilidades/valorItemsTotalCosto";
import {valorTotalIncrementos} from "../Utilidades/valorTotalIncrementos";
import {useIngresarObjeto} from "../../Modulo_formularios/Hooks/useIngresarObjeto";
import Tarjeta_Descuentos from "../Tarjetas/Tarjeta_Descuentos";
import Formulario_Abonos from "./Formulario_Abonos";
import Tarjeta_Abono from "../Tarjetas/Tarjeta_Abono";
import {valorTotalDescuentos} from "../Utilidades/valorTotalDescuentos";

const Formulario_Orden_Abonos = ({...props}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const {IngresarObjeto} = useIngresarObjeto({
        props: props,
        nombre: 'Ingresar Abono',
        Formulario: Formulario_Abonos,
        dato: 'abonos',
        Tarjeta: Tarjeta_Abono
    })
    const [totalItems, setTotalItems] = useState(0)
    const [totalCostoItems, setTotalCostoItems] = useState(0)
    const [totalIncrementos, setTotalIncrementos] = useState(0)
    const [totalDescuentos, setTotalDescuentos] = useState(0)
    const [totalAbonos, setTotalAbonos] = useState(0)
    const [total, setTotal] = useState(0)
    const [open, setOpen] = useState(false)

    const [saldo, setSaldo] = useState(0)

    useEffect(() => {

        let arr = props.props.getValues('abonos')


        if (arr && arr.length > 0) {
            let totAb = 0

            for (let i = 0; i < arr.length; i++) {
                totAb = totAb + arr[i].valorAbono
            }

            let saldoTot = total - totAb
            setSaldo(saldoTot)

            props.props.setValue('totalAbonos', totAb)
            setTotalAbonos(totAb)


        } else {
            props.props.setValue('totalAbonos', 0)
            setTotalAbonos(0)
        }


    }, [props.props.watch(['abonos'])])


    useEffect(() => {


        let items = props.props.getValues('items')
        let incre = props.props.getValues('incrementos')
        let des = props.props.getValues('descuentos')
        let tot = props.props.getValues('total')
        let totItems = valorItemsTotal(items)
        let totCosto = valorItemsTotalCosto(items)
        let totIncre = valorTotalIncrementos(incre)
        let totDes = valorTotalDescuentos(des)
        setTotalIncrementos(totIncre)
        setTotalItems(totItems)
        setTotalCostoItems(totCosto)
        setTotalDescuentos(totDes)
        setTotal(tot)

    }, []);
    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                sx={{pr: sCell ? 1 : 0}}
                spacing={2}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={AccountCircle} nombre={'Nombre'} dato={'nombre'}
                                  requerido={'El nombre es requerido**'} {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={PhoneAndroid} nombre={'Celular'} dato={'celular'}
                                  requerido={'El celular es requerido**'} {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={Badge} nombre={'Nit/Cc'} dato={'cc'}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={Call} nombre={'Tel Secundario'} dato={'telefono'}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={LocationCity} nombre={'Ciudad'} dato={'ciudad'}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={Email} nombre={'Correo Electronico'} dato={'correo'}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto nombre={'Notas adicionales'} dato={'notas'} lineas={3}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Typography sx={{color: theme.palette.primary.main}}>Abonos</Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresarObjeto editable={props.editable}/>
                </Grid>


                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: 'flex-end', px: 1, marginBottom: open ? 0 : -1}}>
                    <ButtonBase
                        onClick={() => setOpen(!open)}
                    >
                        <Typography sx={{fontSize: 12, color: theme.palette.primary.main}}>
                            {open ? 'Ver menos' : 'Ver mas'}
                        </Typography>
                    </ButtonBase>
                </Grid>
                <Collapse in={open} onClick={() => setOpen(false)}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={2}
                    >


                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end', px: 1}}>
                            <Typography sx={{borderColor: '#00000080'}}>Total Items: <span
                                style={{pl: 5, fontWeight: 600}}>{formatoMoneda(totalItems)}</span></Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: 'flex-end', px: 1, marginTop: -2}}>
                            <Typography sx={{borderBottom: 0, borderColor: '#00000080'}}>Incrementos: <span
                                style={{pl: 5, fontWeight: 600}}>{formatoMoneda(totalIncrementos)}</span></Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: 'flex-end', px: 1, marginTop: -2}}>
                            <Typography sx={{borderBottom: 1, borderColor: '#00000080'}}>Descuentos: <span
                                style={{
                                    pl: 5,
                                    fontWeight: 600
                                }}>{formatoMoneda(totalDescuentos * -1)}</span></Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: 'flex-end', px: 1, marginTop: -1}}>
                            <Typography sx={{borderColor: '#00000080'}}>Sub Total: <span
                                style={{
                                    pl: 5,
                                    fontWeight: 600
                                }}>{formatoMoneda(totalItems + totalIncrementos - totalDescuentos)}</span></Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: 'flex-end', px: 1, marginTop: -2}}>
                            <Typography sx={{borderColor: '#00000080', borderBottom: 1}}>Iva 19%: <span
                                style={{
                                    pl: 5,
                                    fontWeight: 600
                                }}>{formatoMoneda(obtenerIva(totalItems + totalIncrementos - totalDescuentos))}</span></Typography>
                        </Grid>
                    </Grid>
                </Collapse>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end', px: 1, marginTop: -1}}>
                    <Typography sx={{borderColor: '#00000080'}}>Valor Orden: <span
                        style={{
                            pl: 5,
                            fontWeight: 600
                        }}>{formatoMoneda(total)}</span></Typography>
                </Grid>


                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: 'flex-end', px: 1, marginTop: -2, marginBottom: 0}}>
                    <Typography sx={{borderColor: '#00000080', borderBottom: 1, paddingBottom: 0.5}}>Total Abonos: <span
                        style={{pl: 5, fontWeight: 600}}>{formatoMoneda(totalAbonos)}</span></Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: 'flex-end', px: 1, marginTop: -1}}>
                    <Typography sx={{borderBottom: 0, borderColor: '#00000080'}}>Saldo: <span
                        style={{pl: 5, fontWeight: 600}}>{formatoMoneda(total - totalAbonos)}</span></Typography>
                </Grid>


            </Grid>
        </form>
    )

}
export default Formulario_Orden_Abonos