/**************************************************
 * Nombre:       Footer
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import logo from '../Recursos/logo_redondo.svg'
import logoentero from '../Recursos/logo_oscuro.svg'
import {theme} from "../Tema";
import {useDialogo} from "../Modulo_dialogos/Hooks/useDialogo";
import Dialogo_Pqrs from "./Dialogos/Dialogo_Pqrs";

const Footer = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const sTab = useMediaQuery(theme.breakpoints.only('sm'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const {abrir, Dialogo} = useDialogo({Componente: Dialogo_Pqrs, titulo: "Radicando PQRS"})

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{backgroundColor: '#038BFF20', backdropFilter: 'blur(5px)'}}
        >

            <Dialogo/>

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', p: 6}}
            >

                <Grid item container lg={4} sm={6} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Typography sx={{color: '#fff', fontWeight: 700, fontSize: 20}}>
                                Contactos
                            </Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 1}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                {contactos.map((item, index) => {
                                    return (
                                        <Grid key={`asd${index}`} item container lg={12} sm={12} xs={12}
                                              sx={{justifyContent: 'flex-start'}}>
                                            <Typography sx={{color: '#fff', fontWeight: 400, fontSize: 16}}>
                                                {item}
                                            </Typography>
                                        </Grid>
                                    )
                                })}

                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>

                <Grid item container lg={5} sm={6} xs={12}
                      sx={{justifyContent: 'flex-start', marginTop: sCell ? 2 : 0}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Typography sx={{color: '#fff', fontWeight: 700, fontSize: 20}}>
                                PQRS
                            </Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 1}}>

                            <Typography sx={{color: '#fff', fontWeight: 400, fontSize: 16}}>
                                Para nosotros la calidad del servicio es muy importante. Radica tu petición, queja,
                                reclamo o sugerencia.
                            </Typography>

                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 2}}>
                            <Button
                                onClick={() => abrir()}
                                sx={{
                                    background: 'linear-gradient(87.37deg, #0D5080 6.26%, #0F94D5 70.27%)', px: 6
                                }}>
                                Radicar
                            </Button>
                        </Grid>


                    </Grid>
                </Grid>

                <Grid item container lg={3} sm={12} xs={12}
                      sx={{justifyContent: masSM ? 'flex-end' : 'center', pr: sCell ? 0 : 4, marginTop: masSM ? 1 : 6}}>
                    <img src={masSM ? logo : logoentero} width={sCell ? '90%' : sTab ? '50%' : 150} height={'auto'}
                         alt={'Logo EyT Soluciones'}/>
                </Grid>


            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end', px: 2, py: 1, backgroundColor: '#000'}}>
                <Typography sx={{color: '#fff', fontSize: 14}} >version: 1.3.0 </Typography>
            </Grid>

        </Grid>
    )

}
export default Footer


const contactos = [
    'info@eytsoluciones.com.co',
    '+57 316 889 2075',
    '+57 318 610 08 96'
]