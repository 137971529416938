export const valorTotalIncrementos = (arr=[]) => {

    if (!arr){
        return  0
    }

    let tot = 0
    for (let i = 0 ; i < arr.length; i++){
        let item = arr[i]
        tot = tot + parseInt(item.valorIncremento)
    }

    return tot
}