/**************************************************
 * Nombre:       Logo Titulo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Font, StyleSheet, Text, View} from '@react-pdf/renderer';
import f400 from '../Recursos/Poppins-Regular.ttf'
import f500 from '../Recursos/Poppins-Medium.ttf'
import f600 from '../Recursos/Poppins-SemiBold.ttf'
import f700 from '../Recursos/Poppins-Bold.ttf'
import f800 from '../Recursos/Poppins-Black.ttf'


const Datos_Empresa = ({datos}) => {
    Font.register({
        family: 'Poppins',
        fonts: [
            {
                src: f400,
                fontWeight: 400
            },
            {
                src: f500,
                fontWeight: 500
            },
            {
                src: f600,
                fontWeight: 600
            },
            {
                src: f700,
                fontWeight: 700
            },
            {
                src: f800,
                fontWeight: 800
            },
        ]
    })


    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#fff',
            paddingTop: 32,
            paddingBottom: 32,
            paddingLeft: 32,
            paddingRight: 40
        },
        gitc: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            alignContent: 'flex-start',
        },
        git: {
            flexDirection: 'row',
            alignItems: 'flex-start',
            alignContent: 'flex-start',
        },
        gitf: {
            flexDirection: 'row',
            alignItems: 'flex-end',
            alignContent: 'flex-end',
        },
        tTitulo: {
            fontSize: 20,
            fontFamily: 'Poppins',
            fontWeight: 700,
            color: '#0C4F80'
        },
        tMed: {
            fontSize: 10,
            fontFamily: 'Poppins',
            fontWeight: 500,
        },
        tNor: {
            fontSize: 10,
            fontFamily: 'Poppins',
            fontWeight: 300,
            color: '#0C4F80'
        }


    });


    return (

        <View style={styles.gitc}>


            <View style={styles.git}>
                <Text style={styles.tNor}>Eyt Soluciones S.A.S</Text>
            </View>

            <View style={{...styles.git, marginTop: -3}}>
                <Text style={styles.tNor}>Nit: 901805284-9</Text>
            </View>

            <View style={{...styles.git, marginTop: -3}}>
                <Text style={styles.tNor}>Régimen Simple de Tributación</Text>
            </View>

            <View style={{...styles.git, marginTop: -3}}>
                <Text style={styles.tNor}>Abstenerse de realizar retenciones</Text>
            </View>


        </View>


    )
}

export default Datos_Empresa;
