/**************************************************
 * Nombre:       Formulario_Persona
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography, useMediaQuery} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {AccountCircle, Badge, Call, Email, LocationCity, PhoneAndroid} from "@mui/icons-material";
import {React, useEffect, useState} from "react";
import {useIngresarObjeto} from "../../Modulo_formularios/Hooks/useIngresarObjeto";
import Formulario_Descuentos from "./Formulario_Descuentos";
import Tarjeta_Descuentos from "../Tarjetas/Tarjeta_Descuentos";
import {theme} from "../../Tema";
import {formatoMoneda} from "../../Utilidades/formatoMoneda";
import {valorItemsTotal} from "../Utilidades/valorItemsTotal";
import {obtenerIva} from "../Utilidades/obtenerIva";
import {valorItemsTotalCosto} from "../Utilidades/valorItemsTotalCosto";
import {valorTotalIncrementos} from "../Utilidades/valorTotalIncrementos";

const Formulario_Cotizacion_Descuento = ({...props}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const {IngresarObjeto} = useIngresarObjeto({
        props: props,
        nombre: 'Ingresar Descuento',
        Formulario: Formulario_Descuentos,
        dato: 'descuentos',
        Tarjeta: Tarjeta_Descuentos
    })
    const [totalItems, setTotalItems] = useState(0)
    const [totalCostoItems, setTotalCostoItems] = useState(0)
    const [totalIncrementos, setTotalIncrementos] = useState(0)
    const [totalDescuentos, setTotalDescuentos] = useState(0)
    const [total, setTotal] = useState(0)

    useEffect(() => {

        let arr = props.props.getValues('descuentos')


        if (arr && arr.length > 0) {
            let totDes = 0

            for (let i = 0; i < arr.length; i++) {
                totDes = totDes + arr[i].valorDescuento

            }

            setTotalDescuentos(totDes)
            let totalCot = totalItems + totalIncrementos - totDes + (obtenerIva(totalItems + totalIncrementos - totDes))
            setTotal(totalCot)

            props.props.setValue('valorDescuentos', totDes)
            props.props.setValue('costo', totalCostoItems + totDes + totalIncrementos)
            props.props.setValue('total', totalCot)

        } else {
            setTotalDescuentos(0)
            props.props.setValue('valorDescuentos', 0)
            let totalCot = totalItems + totalIncrementos + (obtenerIva(totalItems + totalIncrementos))
            setTotal(totalCot)
            props.props.setValue('total', totalCot)
            props.props.setValue('costo', totalCostoItems + totalIncrementos)
        }


    }, [props.props.watch(['descuentos'])])


    useEffect(() => {


        let items = props.props.getValues('items')
        let incre = props.props.getValues('incrementos')
        let totItems = valorItemsTotal(items)
        let totCosto = valorItemsTotalCosto(items)
        let totIncre = valorTotalIncrementos(incre)
        setTotalIncrementos(totIncre)
        setTotalItems(totItems)
        setTotalCostoItems(totCosto)

    }, []);
    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                sx={{pr: sCell ? 1 : 0}}
                spacing={2}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={AccountCircle} nombre={'Nombre'} dato={'nombre'}
                                  requerido={'El nombre es requerido**'} {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={PhoneAndroid} nombre={'Celular'} dato={'celular'}
                                  requerido={'El celular es requerido**'} {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={Badge} nombre={'Nit/Cc'} dato={'cc'}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={Call} nombre={'Tel Secundario'} dato={'telefono'}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={LocationCity} nombre={'Ciudad'} dato={'ciudad'}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={Email} nombre={'Correo Electronico'} dato={'correo'}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto nombre={'Notas adicionales'} dato={'notas'} lineas={3}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Typography sx={{color: theme.palette.primary.main}}>Descuentos</Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresarObjeto editable={props.editable}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end', px: 1}}>
                    <Typography sx={{borderColor: '#00000080'}}>Total Items: <span
                        style={{pl: 5, fontWeight: 600}}>{formatoMoneda(totalItems)}</span></Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end', px: 1, marginTop: -2}}>
                    <Typography sx={{borderBottom: 0, borderColor: '#00000080'}}>Incrementos: <span
                        style={{pl: 5, fontWeight: 600}}>{formatoMoneda(totalIncrementos)}</span></Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end', px: 1, marginTop: -2}}>
                    <Typography sx={{borderBottom: 1, borderColor: '#00000080'}}>Descuentos: <span
                        style={{pl: 5, fontWeight: 600}}>{formatoMoneda(totalDescuentos * -1)}</span></Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end', px: 1, marginTop: -1}}>
                    <Typography sx={{borderColor: '#00000080'}}>Sub Total: <span
                        style={{
                            pl: 5,
                            fontWeight: 600
                        }}>{formatoMoneda(totalItems + totalIncrementos - totalDescuentos)}</span></Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end', px: 1, marginTop: -2}}>
                    <Typography sx={{borderColor: '#00000080', borderBottom: 1}}>Iva 19%: <span
                        style={{
                            pl: 5,
                            fontWeight: 600
                        }}>{formatoMoneda(obtenerIva(totalItems + totalIncrementos - totalDescuentos))}</span></Typography>
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end', px: 1, marginTop: -1}}>
                    <Typography sx={{borderColor: '#00000080'}}>Total Cotizacion: <span
                        style={{
                            pl: 5,
                            fontWeight: 600
                        }}>{formatoMoneda(total)}</span></Typography>
                </Grid>


            </Grid>
        </form>
    )

}
export default Formulario_Cotizacion_Descuento