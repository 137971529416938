/**************************************************
 * Nombre:       Mesa_Trabajo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import mano from "../../Recursos/mano_energia.png";
import {theme} from "../../Tema";
import {FiberManualRecord} from "@mui/icons-material";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import Dialogo_HelpDesk from "../Dialogos/Dialogo_HelpDesk";
import {useScrolY} from "../../Modulo_Efectos_Scroll/Hooks/useScrolY";

const Mesa_Trabajo = () => {
    const {Dialogo, abrir, cerrar} = useDialogo({Componente: Dialogo_HelpDesk, titulo: 'Creando Nuevo Ticket'})
    const {y, motion} = useScrolY({velocidad: 0.1})
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const sTab = useMediaQuery(theme.breakpoints.only('sm'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
        >

            <Dialogo/>

            {masSM &&
                <Grid item container lg={6} sm={12} xs={12}
                      sx={{justifyContent: 'flex-start', marginTop: 18, zIndex: 2}}>
                    <motion.img src={mano} width={'110%'} height={'auto'} alt={'Energia limpia EyT Soluciones'}
                                style={{margin: -50, y: y}}/>
                </Grid>
            }


            <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'flex-start', zIndex: 3}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{
                        justifyContent: 'flex-start',
                        borderLeft: 1,
                        borderColor: theme.palette.primary.main
                    }}>
                        <Typography sx={{color: theme.palette.primary.main, px: 1}}>Soporte tecnico en
                            nuestros servicios</Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{
                        justifyContent: 'flex-start',
                        marginTop: sCell ? 2 : 4
                    }}>
                        <Typography sx={{
                            color: '#fff',
                            fontSize: sCell ? 30 : 42,
                            fontWeight: 700,
                            lineHeight: 1.2
                        }}>
                            Helpdesk
                            <span style={{paddingLeft: 8, fontSize: 18}}>    (Mesa de ayuda)</span>

                        </Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{
                        justifyContent: 'flex-start',
                        marginTop: sCell ? 2 : 4
                    }}>
                        <Typography sx={{
                            color: '#fff',
                            fontSize: 16,
                            fontWeight: 400,
                        }}>
                            Si presentas alguna novedad en alguno de nuestros servicios o productos crea un
                            ticket de atención describiéndolo y si es posible agrega fotos, esto acelerara
                            nuestra capacidad de acción
                        </Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{justifyContent: sCell ? 'center' : 'flex-start', marginTop: 4}}>
                        <Button
                            onClick={() => abrir()}
                            startIcon={<FiberManualRecord color={'info'}/>} color={'action'}
                            sx={{color: '#000C22', py: 1,}}>
                            Abrir Ticket
                        </Button>
                    </Grid>

                    <Grid item container lg={10} sm={12} xs={12} sx={{
                        justifyContent: 'flex-start',
                        marginTop: 4
                    }}>
                        <Typography sx={{
                            color: '#fff',
                            fontSize: 14,
                            fontWeight: 400,
                        }}>
                            *Los informes de soporte tecnico serán enviados al correo electrónico con el que
                            se creo el Ticket
                        </Typography>
                    </Grid>

                </Grid>

            </Grid>


        </Grid>
    )

}
export default Mesa_Trabajo