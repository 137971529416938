import {getMessaging, getToken} from "firebase/messaging";
import {app} from "../../fire";
import {useState, useEffect} from 'react'

export const useObtenerToken = ({ApiKey}) => {
    const [token,setToken] = useState(null)


    const obtenerTok = () => {


        const messaging = getMessaging(app);
        getToken(messaging, {vapidKey: ApiKey}).then((currentToken) => {
            if (currentToken) {

                setToken(currentToken)

            } else {

             //   console.log('No registration token available. Request permission to generate one.');
                setToken(null)

            }
        }).catch((err) => {
           // console.log('An error occurred while retrieving token. ', err);
            setToken(null)
        });

    }


    useEffect(() =>{

        try {
            navigator.serviceWorker.register('firebase-messaging-sw.js');
            Notification.requestPermission(function (result) {
                if (result === 'granted') {
                    obtenerTok();
                }else{
                    // activar snakbar de por favor aceptar notificaciones
                }
            });
        }catch (e){

        }


    },[])
    return{
        token
    }


}