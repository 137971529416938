/**************************************************
 * Nombre:       Formulario_Clientes
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, useMediaQuery} from "@mui/material";
import {
    AccountCircle,
    Badge,
    Call,
    ContentPasteGo,
    Email,
    LocationCity,
    PhoneAndroid,
    Store
} from "@mui/icons-material";
import {useEffect, useState} from "react";
import {obtenerCol} from "../../Servicios/BD/obtenerCol";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {theme} from "../../Tema";


const Formulario_Copiar = ({...props}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [productos, setProductos] = useState([])

    useEffect(() => {
        obtenerCol('productos').then((dox) => {
            if (dox.res) {
                let arr = dox.data.map((it) => it.nombre)
                setProductos(arr)
            }

        })
    }, []);
    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{pr: sCell ? 1 : 0}}
                spacing={2}
            >
                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={ContentPasteGo} nombre={'Numero de Cotizacion'} dato={'numero'}
                                  requerido={'El numero de cotizacion es requerido**'} {...props}
                    />
                </Grid>


            </Grid>
        </form>
    )

}
export default Formulario_Copiar