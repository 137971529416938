import axios from "axios";


export const funDesSuscribirTopic = ({token, topic, key}) => {

    const fcm = "https://iid.googleapis.com/iid/v1:batchRemove"

    let headers = {
        "Authorization": "key=" + key,
        "Content-Type": "application/json",
    }

    axios.post(fcm, {
        "to": "/topics/" + topic,
        "registration_tokens": [token]
    }, {headers}).then((doc) => {
        console.log("Des-suscripcion realizada a: " + topic)
    }).catch((err) => {
        alert(err)
    })
}