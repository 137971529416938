/**************************************************
 * Nombre:       BarraCell
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, useMediaQuery} from "@mui/material";
import logo from "../../Recursos/logo_oscuro.svg";
import {theme} from "../../Tema";

const BarraCell = ({abrir}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{py: 3}}


        >

            <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                <img src={logo} width={200} height={'auto'}

                />
            </Grid>

            <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-end'}}>
                <Button
                    onClick={() => abrir()}
                    sx={{
                        background: 'linear-gradient(87.37deg, #0D5080 6.26%, #0F94D5 70.27%)', pb: 0.7,
                        fontWeight: sCell ? 500 : 600
                    }}>
                    Ingresar
                </Button>
            </Grid>



        </Grid>
    )

}
export default BarraCell    