/**************************************************
 * Nombre:       Formulario_Persona
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {CurrencyExchange, Widgets} from "@mui/icons-material";
import IngresoDinero from "../../Modulo_formularios/Componentes/IngresoDinero";
import IngresoFecha from "../../Modulo_formularios/Componentes/IngresoFecha";

const Formulario_Abonos = ({...props}) => {


    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >


                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoDinero Icono={CurrencyExchange} nombre={'Valor del Abono'} dato={'valorAbono'}
                                   requerido={'El valor del abono es requerido**'}
                                   {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoFecha Icono={CurrencyExchange} nombre={'Fecha del abono'} dato={'fecha'}
                                  requerido={'La fecha del abono es requerido**'}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={Widgets}
                                  lineas={3}
                                  nombre={'Descripcion'}
                                  dato={'descripcion'}
                                  {...props}
                    />
                </Grid>


            </Grid>
        </form>
    )

}
export default Formulario_Abonos