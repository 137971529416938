/**************************************************
 * Nombre:       Formulario_Login
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {AccountCircle, Business, Email, Factory, Flag, Password, WhatsApp} from "@mui/icons-material";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import {TIPONOVEDAD} from "../../Constantes";
import IngresoImagenCropLista from "../../Modulo_formularios/Componentes/IngresoImagenCropLista";

const Formulario_Ticket = ({...props}) => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <IngresoTexto Icono={Factory} nombre={'Nombre de la empresa o persona'} dato={"nombre"} {...props}
                              requerido={'El nombre es requerido*'}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <IngresoTexto Icono={AccountCircle} nombre={'Persona que reporta la novedad'}
                              dato={"persona"} {...props}
                />
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <IngresoTexto Icono={Email} nombre={'Correo electronico'} dato={"correo"} {...props}
                              requerido={'El correo es requerido*'}
                />
            </Grid>

            <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                <IngresoTexto Icono={WhatsApp} nombre={'Numero WhatsApp'} dato={"wass"} {...props}
                />
            </Grid>

            <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                <IngresoSelect Icono={Flag} nombre={'Tipo de novedad'} dato={"tipo"} {...props} opciones={TIPONOVEDAD}
                               requerido={'El tipo de novedad es requerido*'}
                />
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <IngresoTexto Icono={Password} nombre={'Descripcion de la novedad'} dato={"descripcion"} {...props}
                              lineas={6}
                              requerido={'El correo es requerido*'}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', px: 4}}>
                <Typography sx={{fontWeight: 600, fontSize: 16}}>
                    Fotografias
                </Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <IngresoImagenCropLista Icono={Password}
                                        dato={"anexos"} {...props}/>
            </Grid>


        </Grid>
    )

}
export default Formulario_Ticket