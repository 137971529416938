import axios from "axios";


export const funSuscribirTopic = (token, topic, key) =>{

    const fcm = "https://iid.googleapis.com/iid/v1/"+token+'/rel/topics/'+topic

    let headers = {
        "Authorization": "key=" + key,
        "Content-Type": "application/json",
    }

    axios.post(fcm,{},  {headers}).then((doc) => {
        console.log("Suscripcion realizada a: " + topic)
    }).catch((err) => {
        alert(err)
    })
}