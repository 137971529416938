/**************************************************
 * Nombre:       Dialogo_HelpDesk
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import Formulario_Ticket from "../Formularios/Formulario_Ticket";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_redondo.svg'
import {subirVariasImagenFile} from "../../Servicios/Storage/subirVariasImagenFile";
import {theme} from "../../Tema";
import {funEnviarNotificacionTopic} from "../../M_Notificaciones/Funciones/funEnviarNotificacionTopic";

const Dialogo_HelpDesk = ({cerrar}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const {props, obtenerEntidad, limpiarEntidad} = useFormulario({valoresDefecto: {}})
    const {abrirCargador, Cargador, cerrarCargador} = useLoaders({logo: logo})

    const guardarTiket = () => {
        obtenerEntidad().then((entidad) => {
            let obj = entidad
            obj.fecha = new Date().getTime()
            obj.estado = 'Nuevo'
            abrirCargador('Subiendo datos, por favor espere')
            subirVariasImagenFile(entidad.anexos).then((dox) => {
                if (dox.res) {
                    obj.anexos = dox.data
                    guardarDatos(obj)
                }
            })


        })
    }


    const guardarDatos = (obj) => {
        guardarDoc('tickets', obj).then((dox) => {
            cerrarCargador()
            if (dox.res) {
                funEnviarNotificacionTopic({
                    topic: 'admin',
                    key:'AAAAX6dxla4:APA91bHkrsHc0702NFYoT8eZLvwarPtlWMYKuWo-D-JZ0qewfgJDvKbqMeH5AqFmj7H1hBcBRqKJlo4chDDDjEFMRVPw6vS3PhJTQQGa0zLo15zKS8wPDgtJ6WSmkdBr_vq9kndgQvBu',
                    texto: `Nuevo Ticket de ${obj.empresa ? obj.empresa : obj.nombre}`,
                    titulo:'Nuevo Ticket'
                })
                limpiarEntidad()
                cerrar()
                alert('Ticket creado con exito\nMuy pronto nos comunicaremos con ustedes')
            } else {
                alert('Algo inesperado paso, por favor intentalo mas tarde')
            }
        })
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"

        >

            <Cargador/>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 0}}>
                <Formulario_Ticket props={props}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 3, marginBottom: 2}}>
                <Button
                    onClick={() => guardarTiket()}
                >
                    Crear ticket
                </Button>
            </Grid>


        </Grid>
    )

}
export default Dialogo_HelpDesk