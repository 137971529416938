/**************************************************
 * Nombre:       Tarjeta_Item_Lista
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {ButtonBase, Grid, Typography} from "@mui/material";
import {formatoMoneda} from "../../Utilidades/formatoMoneda";

const Tarjeta_Item_Lista = ({item, click, index}) => {

    return (
        <ButtonBase
            onClick={() => click(item, index)}
            sx={{width: '100%', textAlign: 'left'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{px: 1, borderBottom: 1, borderColor: '#00000080', py: 0.5}}
            >


                <Grid item container lg={8} sm={8} xs={8} sx={{justifyContent: 'flex-start'}}>
                    <Typography sx={{fontSize: 12}}> <span
                        style={{fontWeight: 600, paddingRight: 5, fontSize: 14}}>{item.cantidad}</span>{item.nombre}
                    </Typography>
                </Grid>

                <Grid item container lg={4} sm={4} xs={4} sx={{justifyContent: 'flex-end'}}>
                    <Typography>{formatoMoneda(item.cantidad * item.valor)}</Typography>
                </Grid>


            </Grid>
        </ButtonBase>

    )

}
export default Tarjeta_Item_Lista