/**************************************************
 * Nombre:       IndicadorRendimiento
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography, useMediaQuery} from "@mui/material";
import {formatoMoneda} from "../../../Utilidades/formatoMoneda";
import {diferenciaPorcentaje} from "../../../Utilidades/diferenciaPorcentaje";
import {porcentajeDosValore} from "../../../Utilidades/porcentajeDosValore";

const IndicadorNoMoneda = ({titulo, detalle, valorActual, valorAnterior, ceparador, masSM}) => {




    return (
        <Grid item container lg={3} sm={3} xs={12} sx={{justifyContent: 'flex-end'}}>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{pl: masSM ? 3 : 1}}
            >

                <Grid item container lg={11} sm={11} xs={11} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Typography
                                sx={{color: '#000', fontFamily: 'Poppins', fontWeight: 500, fontSize: masSM ? 16 : 14}}>
                                {titulo}
                            </Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Typography
                                sx={{color: '#000', fontFamily: 'Poppins', fontWeight: 600, fontSize: masSM ? 24 : 20}}>
                                {valorActual}
                            </Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Typography
                                sx={{
                                    color: '#5f9658',
                                    fontFamily: 'Montserrat',
                                    fontWeight: 600,
                                    fontSize: masSM ? 12 : 10,
                                }}>
                                {valorAnterior}<span
                                style={{color: '#000', fontWeight: 400, paddingLeft: 5}}>  {detalle} </span>
                            </Typography>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item container lg={1} sm={1} xs={1}
                      sx={{
                          justifyContent: 'flex-end',
                          borderRight: 1.5,
                          borderColor: '#00000050',
                          height: ceparador ? 0 : 50
                      }}>

                </Grid>


            </Grid>
        </Grid>
    )

}
export default IndicadorNoMoneda