/**************************************************
 * Nombre:       Logo Titulo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Font, StyleSheet, Text, View} from '@react-pdf/renderer';
import f400 from '../Recursos/Poppins-Regular.ttf'
import f500 from '../Recursos/Poppins-Medium.ttf'
import f600 from '../Recursos/Poppins-SemiBold.ttf'
import f700 from '../Recursos/Poppins-Bold.ttf'
import f800 from '../Recursos/Poppins-Black.ttf'
import {formatoMoneda} from "../../../Utilidades/formatoMoneda";
import {valorItemsTotal} from "../../Utilidades/valorItemsTotal";
import {valorTotalDescuentos} from "../../Utilidades/valorTotalDescuentos";
import {obtenerIva} from "../../Utilidades/obtenerIva";
import {valorTotalIncrementos} from "../../Utilidades/valorTotalIncrementos";
import {valorTotalAbonos} from "../../Utilidades/valorTotalAbonos";


const Totales_Cotizacion = ({datos, orden}) => {
    Font.register({
        family: 'Poppins',
        fonts: [
            {
                src: f400,
                fontWeight: 400
            },
            {
                src: f500,
                fontWeight: 500
            },
            {
                src: f600,
                fontWeight: 600
            },
            {
                src: f700,
                fontWeight: 700
            },
            {
                src: f800,
                fontWeight: 800
            },
        ]
    })


    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#fff',
            paddingTop: 32,
            paddingBottom: 32,
            paddingLeft: 32,
            paddingRight: 40
        },
        gitc: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            alignContent: 'flex-start',
        },
        git: {
            flexDirection: 'row',
            alignItems: 'flex-start',
            alignContent: 'flex-start',
        },
        gitf: {
            flexDirection: 'row',
            alignItems: 'flex-end',
            alignContent: 'flex-end',
        },
        tTitulo: {
            fontSize: 20,
            fontFamily: 'Poppins',
            fontWeight: 700,
            color: '#0C4F80'
        },
        tMed: {
            fontSize: 10,
            fontFamily: 'Poppins',
            fontWeight: 600,
        },
        tNor: {
            fontSize: 10,
            fontFamily: 'Poppins',
            fontWeight: 400,
        }


    });

    let totItems = valorItemsTotal(datos.items)
    let totDescuentos = valorTotalDescuentos(datos.descuentos)
    let totIncrementos = valorTotalIncrementos(datos.incrementos)
    let totAbonos = valorTotalAbonos(datos.abonos)
    let subTotal = totItems + totIncrementos - totDescuentos
    let iva = obtenerIva(subTotal)
    let total = subTotal + iva


    return (

        <View style={styles.gitc}>

            <View style={styles.git}>
                <View style={{...styles.git, width: '65%', height: 120}}>

                </View>

                <View style={{...styles.git, width: '35%'}}>

                    <View style={styles.gitc}>

                        {(totIncrementos > 0 || totDescuentos > 0) &&
                            <View style={styles.git}>
                                <Text style={{...styles.tMed, width: '40%', textAlign: 'right'}}>Valor Items:</Text>
                                <Text style={{
                                    ...styles.tNor,
                                    textAlign: 'right',
                                    width: '60%'
                                }}>{formatoMoneda(valorItemsTotal(datos.items))}</Text>
                            </View>
                        }

                        {totIncrementos > 0 &&
                            <View style={{...styles.git}}>
                                <Text style={{...styles.tMed, width: '40%', textAlign: 'right'}}>Incrememtos:</Text>
                                <Text style={{
                                    ...styles.tNor,
                                    textAlign: 'right',
                                    width: '60%'
                                }}>{formatoMoneda(totIncrementos)}</Text>
                            </View>
                        }

                        {totDescuentos > 0 &&
                            <View style={{...styles.git}}>
                                <Text style={{...styles.tMed, width: '40%', textAlign: 'right'}}>Descuentos:</Text>
                                <Text style={{
                                    ...styles.tNor,
                                    textAlign: 'right',
                                    width: '60%'
                                }}>{formatoMoneda(totDescuentos * -1)}</Text>
                            </View>
                        }

                        {(totIncrementos > 0 || totDescuentos > 0) &&
                            <View
                                style={{
                                    ...styles.git,
                                    width: '100%',
                                    borderBottom: 0.5,
                                    marginTop: 6,
                                    marginBottom: 6
                                }}/>
                        }


                        <View style={styles.git}>
                            <Text style={{...styles.tMed, width: '40%', textAlign: 'right'}}>Sub Total:</Text>
                            <Text style={{...styles.tNor, textAlign: 'right', width: '60%'}}>
                                {formatoMoneda(subTotal)}
                            </Text>
                        </View>

                        <View style={styles.git}>
                            <Text style={{...styles.tMed, width: '40%', textAlign: 'right'}}>Iva 19%:</Text>
                            <Text style={{...styles.tNor, textAlign: 'right', width: '60%'}}>
                                {formatoMoneda(iva)}
                            </Text>
                        </View>

                        <View
                            style={{...styles.git, width: '100%', borderBottom: 0.5, marginTop: 6, marginBottom: 6}}/>

                        <View style={styles.git}>
                            <Text style={{...styles.tMed, width: '40%', textAlign: 'right'}}>Total:</Text>
                            <Text
                                style={{...styles.tNor, textAlign: 'right', width: '60%'}}>{formatoMoneda(total)}</Text>
                        </View>


                        {orden &&

                            <>
                                <View
                                    style={{
                                        ...styles.git,
                                        width: '100%',
                                        borderBottom: 0.5,
                                        marginTop: 6,
                                        marginBottom: 6
                                    }}/>

                                <View style={styles.git}>
                                    <Text style={{...styles.tMed, width: '40%', textAlign: 'right'}}>Abonos :</Text>
                                    <Text style={{...styles.tNor, textAlign: 'right', width: '60%'}}>
                                        {formatoMoneda(totAbonos)}
                                    </Text>
                                </View>

                                <View style={styles.git}>
                                    <Text style={{...styles.tMed, width: '40%', textAlign: 'right'}}>Saldo :</Text>
                                    <Text style={{...styles.tNor, textAlign: 'right', width: '60%'}}>
                                        {formatoMoneda(total - totAbonos)}
                                    </Text>
                                </View>


                            </>


                        }


                    </View>


                </View>
            </View>


        </View>


    )
}

export default Totales_Cotizacion;
