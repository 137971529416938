export const obtenerFecha = (date) => {


    let fecha = new Date(date)


    const obtenerMesPeque = (num) => {
        switch (num) {
            case 0:
                return "ENE";
            case 1:
                return "FEB";
            case 2:
                return "MAR";
            case 3:
                return "ABR";
            case 4:
                return "MAY";
            case 5:
                return "JUN";
            case 6:
                return "JUL";
            case 7:
                return "AGO";
            case 8:
                return "SEP";
            case 9:
                return "OCT";
            case 10:
                return "NOV";
            case 11:
                return "DIC";


        }
    }


    let numero = fecha.getDate();
    let mes = obtenerMesPeque(fecha.getMonth());
    let year = fecha.getFullYear()


    return numero + ' ' + mes + ' ' + year

}