/**************************************************
 * Nombre:       Dialogo_HelpDesk
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import Formulario_Ticket from "../Formularios/Formulario_Ticket";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_redondo.svg'
import {subirVariasImagenFile} from "../../Servicios/Storage/subirVariasImagenFile";
import Formulario_Pqrs from "../Formularios/Formulario_Pqrs";
import {TaskAlt} from "@mui/icons-material";
import {funEnviarNotificacionTopic} from "../../M_Notificaciones/Funciones/funEnviarNotificacionTopic";

const Dialogo_Pqrs = ({cerrar}) => {
    const {props, obtenerEntidad, limpiarEntidad} = useFormulario({valoresDefecto: {tipo: 'Peticion'}})
    const {abrirCargador, Cargador, cerrarCargador} = useLoaders({logo: logo})

    const guardarTiket = () => {
        obtenerEntidad().then((entidad) => {
            let obj = entidad
            obj.fecha = new Date().getTime()
            obj.estado = 'Nueva'
            abrirCargador('Subiendo datos, por favor espere')
            subirVariasImagenFile(entidad.anexos).then((dox) => {
                if (dox.res) {
                    obj.anexos = dox.data
                    guardarDatos(obj)
                }
            })
        })
    }


    const guardarDatos = (obj) => {
        guardarDoc('pqrs', obj).then((dox) => {
            cerrarCargador()
            if (dox.res) {
                funEnviarNotificacionTopic({
                    topic: 'admin',
                    key:'AAAAX6dxla4:APA91bHkrsHc0702NFYoT8eZLvwarPtlWMYKuWo-D-JZ0qewfgJDvKbqMeH5AqFmj7H1hBcBRqKJlo4chDDDjEFMRVPw6vS3PhJTQQGa0zLo15zKS8wPDgtJ6WSmkdBr_vq9kndgQvBu',
                    texto: `Nuevo PQRS de ${obj.empresa ? obj.empresa : obj.nombre}`,
                    titulo:`Nueva ${obj.tipo}`
                })
                limpiarEntidad()
                cerrar()
                alert('Ticket creado con exito\nMuy pronto nos comunicaremos con ustedes')
            } else {
                alert('Algo inesperado paso, por favor intentalo mas tarde')
            }
        })
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"

        >

            <Cargador/>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 0}}>
                <Formulario_Pqrs props={props}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 3, marginBottom: 2}}>
                <Button
                    startIcon={<TaskAlt/>}
                    onClick={() => guardarTiket()}
                >
                   Enviar Pqrs
                </Button>
            </Grid>


        </Grid>
    )

}
export default Dialogo_Pqrs