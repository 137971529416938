import axios from 'axios'

export const funEnviarNotificacionTopic = ({topic, titulo = "", texto = "", img = null, key = ""}) => {
    const fcm = "https://fcm.googleapis.com/fcm/send"

    // corregir si imagen

    let message = {

        "to": "/topics/" + topic,
        "notification": {
            "body": texto,
            "title": titulo,

        },
        android: {
            notification: {
                sound: 'default'
            },
        },
        apns: {
            payload: {
                aps: {
                    sound: 'default'
                },
            },
        },
        webpush: {
            notification: {
                sound: 'default'
            },
        },

    }

    if (img !== null) {
        message.notification.image = img;
    }


    let headers = {
        "Authorization": "key=" + key,
        "Content-Type": "application/json",
    }

    axios.post(fcm, message, {headers}).then((doc) => {
        //  alert("Notificaciones Enviada")
    }).catch((err) => {
        alert(err.message)
    })
}