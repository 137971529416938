/**************************************************
 * Nombre:       Dialogo_Persona
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Checkbox, Grid, Typography, useMediaQuery} from "@mui/material";
import {Flag, Print, TaskAlt} from "@mui/icons-material";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_redondo.svg'
import {React, useState} from "react";
import {PDFDownloadLink} from "@react-pdf/renderer";
import Pdf_Cotizacion from "../PDfs/Pdf_Cotizacion";
import {theme} from "../../Tema";
import Formulario_Cotizacion_Estado from "../Formularios/Formulario_Cotizacion_Estado";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {obtenerConsecutivo} from "../../Servicios/BD/Consecutivos/obtenerConsecutivo";
import {aumentarConsecutivo} from "../../Servicios/BD/Consecutivos/aumentarConsecutivo";

const Dialogo_Cotizacion_Aprobar = ({datos, cerrar, realizado}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const {props, obtenerEntidad, limpiarEntidad} = useFormulario({valoresDefecto: datos})
    const {abrirCargador, cerrarCargador, Cargador} = useLoaders({logo: logo})
    const [entendido, setEntendido] = useState(false)


    const aprobarCotizacion = () => {
        obtenerEntidad().then((entidad) => {
            abrirCargador('Subiendo datos')
            let obj = entidad

            obj.estado = 'Aprobada'


            let orden = {...obj}
            delete orden.id
            orden.estado = 'Nueva'
            orden.fechaAprobada = new Date().getTime()
            orden.consecutivoCotizacion = entidad.consecutivo

            obtenerConsecutivo({col: 'contadores', id: 'ordenes'}).then((des) => {
                if (des.res) {
                    let num = des.data
                    orden.consecutivo = num
                    aumentarConsecutivo({col: 'contadores', id: 'ordenes', actual: num}).then((dux) => {
                        if (dux.res) {
                            guardarOrden(orden, obj)
                        } else {
                            alert('Ups, algo inesperado paso, intentalo nuevamente')
                        }
                        cerrarCargador()
                    })
                } else {
                    cerrarCargador()
                    alert('Ups, algo inesperado paso al asignar consecutivo a la orden, intentalo nuevamente')
                }
            })


        })
    }

    const guardarOrden = (orden, obj) => {
        guardarDoc('ordenes', orden).then((dox) => {
            if (dox.res) {
                alert('Cotizacion aprobada y orden de servicio creada con exito')
                guardarCotizacion(obj)
            } else {
                alert('Ups algo paso, por favor intentalo nuevamente')
            }
        })
    }
    const guardarDatos = () => {
        obtenerEntidad().then((entidad) => {
            abrirCargador('Subiendo datos')
            let obj = entidad
            guardarCotizacion(obj)

        })

    }


    const guardarCotizacion = (obj) => {
        guardarDoc('cotizaciones', obj).then((dox) => {
            cerrarCargador()
            if (dox.res) {
                cerrar()
                realizado()
                alert('Cambios guardados con exito')
            } else {
                alert('Ups algo paso, por favor intentalo nuevamente')
            }
        })
    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{pr: 1}}
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <PDFDownloadLink document={<Pdf_Cotizacion datos={datos}/>} fileName={`Cotizacion #${datos.consecutivo}`}
                                 style={{textDecoration: "none"}}>
                    <Button startIcon={<Print/>} variant={'outlined'} sx={{color: theme.palette.primary.main}}>Ver
                        Cotizacion</Button>
                </PDFDownloadLink>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 2}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item container lg={2} sm={12} xs={2} sx={{justifyContent: 'flex-start', marginTop: -0.8}}>
                        <Checkbox checked={entendido || datos.estado === 'Aprobada'}
                                  onChange={(e) => setEntendido(!entendido)} size={'small'}/>
                    </Grid>

                    <Grid item container lg={10} sm={12} xs={10} sx={{justifyContent: 'flex-start'}}>
                        <Typography sx={{fontSize: 14}}>
                            {datos.estado === 'Aprobada' ?
                                'Esta cotizacion a sido aprobada, no se puede realizar ninguna accion' :
                                'Al aprobar la cotizacion entiendo que esta pasara a ser orden de servicio'
                            }

                        </Typography>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 2, marginBottom: 4}}>
                <Button
                    disabled={!entendido || datos.estado === 'Aprobada'}
                    onClick={() => aprobarCotizacion()}
                    startIcon={<TaskAlt/>}
                >Aprobar Cotizacion</Button>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', borderTop: 1, paddingTop: 4}}>
                <Formulario_Cotizacion_Estado props={props} editable={datos && datos.estado !== 'Aprobada'}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 2, marginBottom: 1}}>
                <Button
                    disabled={datos && datos.estado === 'Aprobada'}
                    onClick={() => guardarDatos()}
                    startIcon={<Flag/>}
                >Guardar Cambios</Button>
            </Grid>


        </Grid>
    )

}
export default Dialogo_Cotizacion_Aprobar