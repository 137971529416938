/**************************************************
 * Nombre:       Formulario_Persona
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {Flag} from "@mui/icons-material";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import {ESTADOSCOTIZACION} from "../../Constantes";

const Formulario_Cotizacion_Estado = ({...props}) => {


    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >


                <Grid item container lg={6} sm={6} xs={8} sx={{justifyContent: 'flex-start'}}>
                    <IngresoSelect Icono={Flag} nombre={'Estado'} dato={'estado'}
                                   requerido={'El estado requerido**'}
                                   opciones={ESTADOSCOTIZACION}
                                   {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto
                        lineas={6}
                        nombre={'Notas Internas'}
                        dato={'notasIternas'}
                        editable={props.editable}
                        {...props}
                    />
                </Grid>


            </Grid>
        </form>
    )

}
export default Formulario_Cotizacion_Estado