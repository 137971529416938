import {motion, useScroll, useTransform} from "framer-motion";

export const useScrolY = ({velocidad = -0.1,}) => {

    let {scrollY} = useScroll();
    let y = useTransform(scrollY, (value) => -value * velocidad);

    return {
        y,
        motion
    }

}