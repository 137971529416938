import React, {createContext, useEffect, useState} from 'react';
import './App.css';
import Dashboard from "./Dashboard/Dashboard";
import {Grid} from "@mui/material";
import {useEscucharAuth} from "./Servicios/Auth/useEscucharAuth";
import {useEscucharUsuarioToCorreo} from "./Servicios/BD/useEscucharUsuarioToCorreo";
import {iniciarAnaliticas} from "./Servicios/Analiticas/iniciarAnaliticas";
import Pagina from "./Pagina/Pagina";
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/800.css';
import {useEscucharNotificaion} from "./M_Notificaciones/Hooks/useEscucharNotificacion";
import {useObtenerToken} from "./M_Notificaciones/Hooks/useObtenerToken";
import {funSuscribirTopic} from "./M_Notificaciones/Funciones/funSuscribirTopic";
import {actualizarDoc} from "./Servicios/BD/actualizarDoc";


export const usuarioContext = createContext();

function App() {
    const {email} = useEscucharAuth()
    const {usuario} = useEscucharUsuarioToCorreo({correo: email})
    const [mesa, setMesa] = useState(0)
    const [puesto, setPuesto] = useState('none')
    const {token} = useObtenerToken({ApiKey: "BBS94cgnftIWGCb9bYV8G-lVmiN6v2IGeSgU5Iz4Ey3FjsB7aosN1Qds1HmyFflQsDitUFQprzkBYCtek-wtk7I"})
    const {Notificacion} = useEscucharNotificaion()

    const valoresProvider = {
        usuario,
        mesa,
        setMesa,
        puesto,
        setPuesto
    }

    const {Provider} = usuarioContext;


    useEffect(() => {

        iniciarAnaliticas()

        if (window.swUpdateReady) {
            window.swUpdateReady = false;
            window.stop();
            window.location.reload();
        }


    }, [])

    let cont = 0

    useEffect(() => {


        if (token !== null && usuario) {
            funSuscribirTopic(token, 'admin', 'AAAAX6dxla4:APA91bHkrsHc0702NFYoT8eZLvwarPtlWMYKuWo-D-JZ0qewfgJDvKbqMeH5AqFmj7H1hBcBRqKJlo4chDDDjEFMRVPw6vS3PhJTQQGa0zLo15zKS8wPDgtJ6WSmkdBr_vq9kndgQvBu')
           /* if (token !== usuario.token && cont < 2) {
                actualizarDoc("usuarios", usuario.id, {"token": token}).then((dox) => {
                    if (dox.res) {
                        cont++

                    }

                })
            }*/
        }
    }, [token, usuario])
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{maxWidth: '100%'}}
        >

            <Notificacion/>

            <Provider value={valoresProvider}>
                {usuario ?
                    <Dashboard/>
                    :
                    <Pagina/>
                }

            </Provider>

        </Grid>
    );
}

export default App;