/**************************************************
 * Nombre:       Pagina
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, useMediaQuery} from "@mui/material";
import fondo from '../Recursos/fondo_pagina.svg'
import Barra from "./Barra/Barra";
import Home from "./Secciones/Home";
import Footer from "./Footer";
import {theme} from "../Tema";

const Pagina = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const sTab = useMediaQuery(theme.breakpoints.only('sm'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{
                backgroundColor: '#000C22'
            }}
        >

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: sCell ? 1 : 3}}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', px: sCell ? 2 : 0, zIndex: 4}}>
                    <Barra/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: 'flex-start', zIndex: 3, marginTop: sCell ? -8 : 0}}>
                    <Home/>
                </Grid>

            </Grid>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: masSM ? -12 : 12, zIndex: 4}}>
                <Footer/>
            </Grid>


        </Grid>
    )

}
export default Pagina    