/**************************************************
 * Nombre:       Formulario_Persona
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {AllInbox, Category, PointOfSaleOutlined, StorefrontOutlined, Widgets} from "@mui/icons-material";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import {useEffect, useState} from "react";
import {obtenerCol} from "../../Servicios/BD/obtenerCol";
import IngresoDinero from "../../Modulo_formularios/Componentes/IngresoDinero";

const Formulario_Item_Lista = ({...props}) => {
    const [productos, setProductos] = useState([])
    const [personas, setPersonas] = useState([])


    useEffect(() => {
        obtenerCol('productos').then((dox) => {
            if (dox.res) {
                let productosNombre = dox.data.map((it) => it.nombre)
                setProductos(productosNombre.sort())
            }
        })

        obtenerCol('personas').then((dox) => {
            if (dox.res) {
                setPersonas(dox.data)
            }
        })


    }, [])
    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >
                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={Category} nombre={'Nombre del item'} dato={'nombre'}
                                   requerido={'El nombre es requerido**'}
                                   opciones={productos}
                                   {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={Widgets}
                                  nombre={'Cantidad'}
                                  dato={'cantidad'}
                                  type={'number'}
                                  requerido={'El valor de venta es requerido**'}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}/>


                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoDinero Icono={StorefrontOutlined} nombre={'Costo de compra'} dato={'costo'}
                                   requerido={'El costo es requerido**'}
                                   {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoDinero Icono={PointOfSaleOutlined} nombre={'Valor de venta'} dato={'valor'}
                                   requerido={'El valor de venta es requerido**'}
                                   {...props}
                    />
                </Grid>


            </Grid>
        </form>
    )

}
export default Formulario_Item_Lista